import React, { useEffect, useState } from "react";
import {
  Accordion,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Text,
  Center,
  Box,
  Flex,
  Divider,
  Tooltip,
  Button
} from "@chakra-ui/react";

import { ITutorialVideo, TutorialVideos } from "../../sources/tutorial";
import { DocumentTextIcon, PlayIcon } from "@heroicons/react/solid";
import { TutorialContent } from "../../sources/support-content";
import { ArrowRightIcon } from "@heroicons/react/solid";
interface IOnSiteSupportModalProps {
  contentId: number;
  isOpen: boolean;
  onClose: () => void;
  headline?: string;
}

interface IOnSiteSupportContent {
  id: number;
  content: any;
  videos: ITutorialVideo[];
}

const SupportContent: IOnSiteSupportContent[] = [
  //LIBRARY - INDEX
  {
    id: 0,
    content: (
      <>
        {TutorialContent.MEDIATHEK.INTRO}

        <Accordion allowToggle reduceMotion>
          {TutorialContent.MEDIATHEK.ITEM_1}
          {TutorialContent.MEDIATHEK.ITEM_2}
          {TutorialContent.MEDIATHEK.ITEM_3}
        </Accordion>
      </>
    ),
    videos: [TutorialVideos[0], TutorialVideos[1]]
  },
  //LIBRARY - DETAIL-PAGE ADMIN
  {
    id: 1,
    content: (
      <>
        <Accordion allowToggle reduceMotion>
          {TutorialContent.MEDIATHEK.ITEM_4}
          {TutorialContent.MEDIATHEK.ITEM_5}
          {TutorialContent.GRUPPIERUNGEN.ITEM_5}
        </Accordion>
      </>
    ),
    videos: [TutorialVideos[1]]
  },
  //LIBRARY - DETAIL-PAGE NORMAL USER
  {
    id: 2,
    content: (
      <>
        <Accordion allowToggle reduceMotion>
          {TutorialContent.MEDIATHEK.ITEM_4}
        </Accordion>
      </>
    ),
    videos: [TutorialVideos[1]]
  },
  //LIBRARY - NEW CONTENT
  {
    id: 3,
    content: (
      <>
        <Accordion allowToggle reduceMotion>
          {TutorialContent.MEDIATHEK.ITEM_3}
        </Accordion>
      </>
    ),
    videos: [TutorialVideos[1]]
  },
  //LIBRARY -EDIT CONTENT ADMIN USER
  {
    id: 4,
    content: (
      <>
        <Accordion allowToggle reduceMotion>
          {TutorialContent.MEDIATHEK.ITEM_3}
          {TutorialContent.MEDIATHEK.ITEM_4}
          {TutorialContent.MEDIATHEK.ITEM_5}
        </Accordion>
      </>
    ),
    videos: [TutorialVideos[1]]
  },
  //LIBRARY -EDIT CONTENT NORMAL USER
  {
    id: 5,
    content: (
      <>
        <Accordion allowToggle reduceMotion>
          {TutorialContent.MEDIATHEK.ITEM_3}
          {TutorialContent.MEDIATHEK.ITEM_4}
        </Accordion>
      </>
    ),
    videos: [TutorialVideos[1]]
  },

  // PRODUCTS INDEX - ADMIN USER
  {
    id: 6,
    content: (
      <>
        <Accordion allowToggle reduceMotion>
          {TutorialContent.PRODUKTVERWALTUNG.ITEM_1}
          {TutorialContent.GRUPPIERUNGEN.ITEM_4}
        </Accordion>
      </>
    ),
    videos: [TutorialVideos[2]]
  },
  // PRODUCTS INDEX - KMZ ADMIN USER
  {
    id: 7,
    content: (
      <>
        {TutorialContent.PRODUKTVERWALTUNG.INTRO}
        <Accordion allowToggle reduceMotion>
          {TutorialContent.PRODUKTVERWALTUNG.ITEM_1}
          {TutorialContent.MSB_ASSIGN_PRODUCTS}
        </Accordion>
      </>
    ),
    videos: []
  },
  // PRODUCTS INDEX - NORMAL USER

  {
    id: 8,
    content: (
      <>
        {TutorialContent.PRODUKTVERWALTUNG.INTRO}
        <Accordion allowToggle reduceMotion>
          {TutorialContent.PRODUKTVERWALTUNG.ITEM_1}
        </Accordion>
      </>
    ),
    videos: [TutorialVideos[4], TutorialVideos[8]]
  },
  // PRODUCTS/DEVICES
  {
    id: 9,
    content: (
      <Accordion allowToggle reduceMotion>
        {TutorialContent.PRODUKTVERWALTUNG.SUB_ITEM_1}
        {TutorialContent.PRODUKTVERWALTUNG.SUB_ITEM_2}
        {TutorialContent.PRODUKTVERWALTUNG.SUB_ITEM_3}
        {TutorialContent.PRODUKTVERWALTUNG.SUB_ITEM_4}
      </Accordion>
    ),
    videos: [TutorialVideos[2]]
  },

  //CONFIGURATIONS - INDEX
  {
    id: 10,
    content: (
      <Accordion reduceMotion allowToggle>
        {TutorialContent.PRODUKTVERWALTUNG.ITEM_2}
      </Accordion>
    ),
    videos: [TutorialVideos[3]]
  },
  //CONFIGURATION - INDEX / EDIT
  {
    id: 11,
    content: (
      <Accordion reduceMotion allowToggle>
        {TutorialContent.PRODUKTVERWALTUNG.ITEM_2}
      </Accordion>
    ),
    videos: [TutorialVideos[3]]
  },

  //MODERATE
  {
    id: 12,
    content: <Box>{TutorialContent.MODERIEREN}</Box>,
    videos: [TutorialVideos[5]]
  },
  //ADMIN-BEREICH
  {
    id: 13,
    content: undefined,
    videos: [TutorialVideos[6]]
  },
  // SUPPORT-BEREICH
  {
    id: 14,
    content: undefined,
    videos: [TutorialVideos[7]]
  },
  //GROUPS INDEX
  {
    id: 15,
    content: (
      <>
        {TutorialContent.GRUPPIERUNGEN.INTRO}
        <Accordion allowToggle reduceMotion>
          {TutorialContent.GRUPPIERUNGEN.ITEM_1}
          {TutorialContent.GRUPPIERUNGEN.ITEM_2}
          {TutorialContent.GRUPPIERUNGEN.ITEM_3}
          {TutorialContent.GRUPPIERUNGEN.ITEM_4}
          {TutorialContent.GRUPPIERUNGEN.ITEM_5}
        </Accordion>
      </>
    ),
    videos: []
  },
  //GROUPS NEW
  {
    id: 16,
    content: (
      <>
        {TutorialContent.GRUPPIERUNGEN.INTRO}
        <Accordion allowToggle reduceMotion>
          {TutorialContent.GRUPPIERUNGEN.ITEM_2}
          {TutorialContent.GRUPPIERUNGEN.ITEM_3}
          {TutorialContent.GRUPPIERUNGEN.ITEM_4}
        </Accordion>
      </>
    ),
    videos: []
  },

  {
    id: 17,
    content: (
      <>
        {TutorialContent.GRUPPIERUNGEN.INTRO}
        <Accordion allowToggle reduceMotion>
          {TutorialContent.GRUPPIERUNGEN.ITEM_2}
          {TutorialContent.GRUPPIERUNGEN.ITEM_3}
          {TutorialContent.GRUPPIERUNGEN.ITEM_4}
        </Accordion>
      </>
    ),
    videos: [TutorialVideos[2]]
  },
  //PORTAL INDEX
  {
    id: 18,
    content: (
      <>
        {TutorialContent.MEDIATHEK.INTRO}
        <Accordion allowToggle reduceMotion>
          {TutorialContent.MEDIATHEK.ITEM_1}
          {TutorialContent.MEDIATHEK.ITEM_2}
          {TutorialContent.MEDIATHEK.ITEM_3}
        </Accordion>
      </>
    ),
    videos: [TutorialVideos[2]]
  }
];

export default function OnSiteSupportModal({ isOpen, onClose, headline = "Modal", contentId }: IOnSiteSupportModalProps) {
  const [activeVideo, setActiveVideo] = useState<ITutorialVideo | undefined>(undefined);
  const [showText, setShowText] = useState(true);

  const handleVideoClick = (video: ITutorialVideo) => {
    setShowText(false);
    setActiveVideo(video);
  };
  const handleTextClick = () => {
    setShowText(true);
    setActiveVideo(undefined);
  };
  const openSupportPage = () => {
    onClose();
    window.open("/support", "_blank");
  };
  const renderVideoTitle = (title: string) => {
    if (title.length > 17) {
      return (
        <Tooltip label={title}>
          <Text> {title.slice(0, 17)} </Text>
        </Tooltip>
      );
    }
    return <Text>{title}</Text>;
  };

  const showVideoTab: boolean = SupportContent[contentId].videos.length > 0;

  useEffect(() => {
    if (showVideoTab && contentId && SupportContent[contentId].content === undefined) {
      setActiveVideo(SupportContent[contentId].videos[0]);
      setShowText(false);
    }
  }, [showVideoTab, contentId]);

  return (
    <Modal size={"3xl"} isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent bgGradient={"linear(to-b, white,  gray.100, white)"} borderRadius="3xl">
        <ModalHeader color={"darkBlue.500"} fontFamily="Bitter" fontWeight={600} mb={0} pb={0}>
          <Text fontSize={"xl"}> Hilfe: {headline}</Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody m={[1, 4]} p={[1, 4]} mt={0} pt={0} bgColor="white" borderRadius="xl">
          {showText ? (
            <Box maxH={"35rem"} overflowY="auto">
              {SupportContent[contentId].content}
            </Box>
          ) : (
            activeVideo && (
              <>
                <Text color={"gray.500"}>{activeVideo.helpText}</Text>
                <Center>
                  <video controls preload={"none"} poster="/img/video-poster.png">
                    <source src={activeVideo.src} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </Center>
              </>
            )
          )}

          <Divider my={4} />

          <SimpleGrid columns={3} gap={4}>
            {SupportContent[contentId].content && (
              <Flex
                height={32}
                cursor={"pointer"}
                onClick={() => handleTextClick()}
                p={2}
                justify="center"
                pos={"relative"}
                color={showText ? "lightBlue.500" : "darkBlue.500"}
                flexDir={"column"}
                align={"center"}
                border={"3px solid"}
                borderRadius="xl"
                borderColor={showText ? "lightBlue.500" : "darkBlue.500"}
              >
                <Flex
                  pos="absolute"
                  top="50%"
                  left="50%"
                  flexDir={"column"}
                  justify="center"
                  align={"center"}
                  transform="translate(-50%, -50%)"
                  borderRadius="full"
                >
                  <DocumentTextIcon className="w-8 h-8 " />

                  <Text mt={"auto"} fontSize={"14px"} textAlign={"center"}>
                    Anleitung
                  </Text>
                </Flex>
              </Flex>
            )}
            {showVideoTab &&
              SupportContent[contentId].videos.map((video, index) => {
                return (
                  <React.Fragment key={index}>
                    <Flex
                      height={32}
                      overflow={"hidden"}
                      pos="relative"
                      color={activeVideo?.id === video.id ? "lightBlue.500" : "darkBlue.500"}
                      gap={2}
                      border={"3px solid"}
                      borderRadius="xl"
                      borderColor={activeVideo?.id === video.id ? "lightBlue.500" : "darkBlue.500"}
                      cursor={"pointer"}
                      flexDir={"column"}
                      justify="center"
                      align={"center"}
                      onClick={() => handleVideoClick(video)}
                    >
                      <video height={20} src={video.src} />

                      <Flex
                        pos="absolute"
                        top="50%"
                        left="50%"
                        flexDir={"column"}
                        justify="center"
                        align={"center"}
                        transform="translate(-50%, -50%)"
                        borderRadius="full"
                      >
                        <PlayIcon className="w-8 h-8 " />
                        <Text fontSize={"14px"} textAlign="center">
                          {renderVideoTitle(video.title)}
                        </Text>
                      </Flex>
                    </Flex>
                  </React.Fragment>
                );
              })}
          </SimpleGrid>

          <Button rightIcon={<ArrowRightIcon className="w-5 h-5" />} onClick={openSupportPage} mt={2} float="right">
            Zur Hilfeseite
          </Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
