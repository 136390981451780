import { Flex, useDisclosure, Tooltip, Box } from "@chakra-ui/react";

import { FaQuestion } from "react-icons/fa";

import { useSupportBuddy } from "../../../provider/supportBuddyProvider";
import OnSiteSupportModal from "../../modals/onSiteSupportModal";

export default function SupportBuddy() {
  const { currentTargetId, currentTargetTitle } = useSupportBuddy();
  const onSiteSupportModal = useDisclosure();
  const {
    isOpen: onSiteSupportModalIsOpen,
    onOpen: onSiteSupportModalOnOpen,
    onClose: onSiteSupportModalOnClose
  } = onSiteSupportModal;

  const handleBuddyOnClick = () => {
    onSiteSupportModalOnOpen();
  };

  if (typeof currentTargetId === "undefined") return <></>;

  return (
    <>
      <Tooltip label="Hilfe">
        <Flex
          justify={"center"}
          align="center"
          zIndex={4}
          pos={"fixed"}
          top={20}
          right={4}
          w={[10, 10, 12, 12]}
          h={[10, 10, 12, 12]}
          border="3px solid"
          onClick={handleBuddyOnClick}
          borderColor={"cta.500"}
          bgColor="cta.500"
          _hover={{ bgColor: "cta.600" }}
          color={"white"}
          borderRadius="100%"
          cursor="pointer"
        >
          <Box m={[2, 2, 3, 3]} w="full" h="full">
            <FaQuestion className="w-full h-full " />
          </Box>
        </Flex>
      </Tooltip>

      <OnSiteSupportModal
        contentId={currentTargetId}
        headline={currentTargetTitle}
        onClose={onSiteSupportModalOnClose}
        isOpen={onSiteSupportModalIsOpen}
      />
    </>
  );
}
