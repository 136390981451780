import React, { RefObject, useEffect, useRef, useState } from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  ButtonGroup,
  Link,
  IconButton,
  Text,
  Image,
  Center,
  Box,
  Flex,
  Button,
  Tooltip,
  useDisclosure
} from "@chakra-ui/react";
import { FaLinkedin, FaInstagram } from "react-icons/fa";
import { ApiUserService, ICurrentPP, ICurrentToS } from "../../services/http";
import { useAuth } from "../../provider/authentication";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import { IS_PRODUCTION } from "../../constants";
import { ApiSocialAuthentication } from "../../services/http/apiSocialAuthentication";
import { useRouter } from "next/router";

interface IFooterProps {
  isMarketplace: boolean;
}

export default function Footer({ isMarketplace }: IFooterProps) {
  const { isAuthenticated, user, isSocialUser, isMSBUser } = useAuth();
  const [currentToSObject, setCurrentToSObject] = useState<ICurrentToS>();
  const cancelRef: RefObject<any> = useRef();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [currentPPObject, setCurrentPPObject] = useState<ICurrentPP>();
  const showMSBContent = user && (isMSBUser || isSocialUser);
  const router = useRouter();
  const openDocument = () => {
    window.open(currentToSObject?.tosFile, "_blank");
  };

  const openMarketplace = () => {
    window.open("https://www.vil.digital/marktplatz", "_blank");
    onClose();
  };

  const handleOnPPClick = () => {
    if (showMSBContent) {
      window.open(currentPPObject?.privacyPolicyFile, "_blank");
    } else {
      router.push("/datenschutz");
    }
  };
  useEffect(() => {
    if (isAuthenticated && !currentToSObject) {
      ApiUserService.getMyToS().then(res => {
        if (res.status === 200) {
          setCurrentToSObject(res.data);
        }
      });
    }
  }, [isAuthenticated, currentToSObject]);

  useEffect(() => {
    if (isAuthenticated && !currentPPObject) {
      ApiUserService.getMyPP().then(res => {
        if (res.status === 200) {
          setCurrentPPObject(res.data);
        }
      });
    }
  }, [isAuthenticated, currentPPObject]);

  return (
    <>
      <Box bg={isMarketplace ? "white" : "darkBlue.500"} w={"100%"} py={8}>
        <Box color={isMarketplace ? "darkBlue.500" : "white"} fontSize={"md"}>
          <Flex gap={{ base: "4", md: "5" }} justifyContent="center" flexDir={"column"}>
            <Flex justifyContent="center" align={"center"} gap={4} flexDir={{ base: "column", lg: "row" }}>
              <Image
                fontFamily={"Assistant"}
                src={isMarketplace ? "/img/vil-logo.png" : "/img/VIL_Logo_negativ_Wortmarke.png"}
                alt={"logo"}
                height={"40px"}
              />
              <Link fontWeight={600} onClick={handleOnPPClick}>
                Datenschutz
              </Link>
              <Link
                fontWeight={600}
                onClick={() => {
                  openDocument();
                }}
              >
                Nutzungsbedingungen
              </Link>
              <Link fontWeight={600} href={"/impressum"}>
                Impressum
              </Link>{" "}
              <ButtonGroup variant={"ghost"}>
                <IconButton
                  as={"a"}
                  color={isMarketplace ? "darkBlue.500" : "white"}
                  href={"https://www.linkedin.com/company/vil-gmbh/"}
                  target={"_blank"}
                  aria-label={"LinkedIn"}
                  icon={<FaLinkedin fontSize={"1.5rem"} />}
                />
                <IconButton
                  as={"a"}
                  color={isMarketplace ? "darkBlue.500" : "white"}
                  href={"https://instagram.com/virtuelles_interaktives_lernen"}
                  target={"_blank"}
                  aria-label={"Instagram"}
                  icon={<FaInstagram fontSize={"1.5rem"} />}
                />
                {showMSBContent && (
                  <Tooltip label="Marktplatz">
                    <Button
                      variant="outline"
                      colorScheme={"white"}
                      _hover={{ bgColor: "darkBlue.600" }}
                      onClick={onOpen}
                      size={{ base: "sm", md: "md", lg: "md" }}
                      leftIcon={<ExternalLinkIcon className="w-5 h-5" />}
                    >
                      Weitere Inhalte (kostenpflichtig)
                    </Button>
                  </Tooltip>
                )}
              </ButtonGroup>
            </Flex>
            <Center>
              <Text fontSize={"sm"} color={"subtle"}>
                &copy; {new Date().getFullYear()} VIL GmbH. All rights reserved.
              </Text>
            </Center>
          </Flex>
        </Box>
      </Box>
      <AlertDialog isCentered isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
        <AlertDialogOverlay>
          <AlertDialogContent bgColor={"darkBlue.500"} color="white">
            <AlertDialogHeader fontFamily={"Bitter"} textAlign="center">
              Kostenpflichtige Inhalte
            </AlertDialogHeader>

            <AlertDialogBody>
              Wenn Sie fortfahren, verlassen Sie die Mediathek und werden zu einem Marktplatz mit kostenpflichtigen Angeboten
              weitergeleitet.
            </AlertDialogBody>

            <AlertDialogFooter display={"flex"} justifyContent="space-between">
              <Button colorScheme={"whiteAlpha"} onClick={openMarketplace} ml={3}>
                Fortfahren
              </Button>
              <Button colorScheme={"whiteAlpha"} ref={cancelRef} onClick={onClose}>
                Abbrechen
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
}
