import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Divider,
  Link,
  ListItem,
  OrderedList,
  Text,
  UnorderedList
} from "@chakra-ui/react";
import SupportInfoImage from "../components/support/components/support-info-image";

export interface ITutorialContent {
  MEDIATHEK: {
    INTRO: any;
    ITEM_1: any;
    ITEM_2: any;
    ITEM_3: any;
    ITEM_4: any;
    ITEM_5: any;
  };
  PRODUKTVERWALTUNG: {
    SUB_ITEM_1: any;
    SUB_ITEM_2: any;
    SUB_ITEM_3: any;
    SUB_ITEM_4: any;
    INTRO: any;
    INDEX: any;
    ITEM_1: any;
    ITEM_2: any;
  };
  MSB_ASSIGN_PRODUCTS: any;

  MODERIEREN: any;
  GRUPPIERUNGEN: {
    INTRO: any;
    ITEM_1: any;
    ITEM_2: any;
    ITEM_3: any;
    ITEM_4: any;
    ITEM_5: any;
  };
  VIL_CONNECT: any;
  VIL_LAUNCHER: {
    ITEM_1_DEFAULT: any;
    ITEM_1_ADMIN: any;
  };
}
export const TutorialContent: ITutorialContent = {
  MEDIATHEK: {
    INTRO: (
      <>
        <Text pt={4}>
          Die Mediathek des VIL Portals dient vorrangig der Vorbereitung Ihrer VIL-Produkte (Lernwagen/Lernpult). Hier finden Sie
          alle Inhalte welche Ihnen seitens der VIL zur Verfügung gestellt werden.
        </Text>
        <Text pb={4}>Auch Ihre eigenen Inhalte können hier hochgeladen und verwaltet werden.</Text>
      </>
    ),
    ITEM_1: (
      <AccordionItem>
        <h2>
          <AccordionButton>
            <Box flex="1" textAlign="left" py={"2"} h={"full"}>
              <Text color={"darkBlue.500"} fontWeight={600} fontSize={"large"}>
                Inhalte / Inhaltsarten
              </Text>
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4}>
          <>
            <Text py={4}>Das VIL Portal bietet Ihnen ein umfangreiches Angebot an 360° Videos, Anwendungen und Web Apps.</Text>
            <Accordion allowToggle reduceMotion>
              <AccordionItem>
                <h2>
                  <AccordionButton>
                    <Box flex="1" textAlign="left" py={"2"} h={"full"}>
                      <Text fontSize={"md"}> </Text>
                      <Text color={"darkBlue.500"} fontWeight={600} fontSize={"large"}>
                        360° Videos
                      </Text>
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <Text mb={2}>
                    Im Gegensatz zu herkömmlichen Videos, bei denen Sie auf einen Bildschirm schauen, ermöglichen VR-Videos ein
                    interaktives und immersives Erlebnis.
                  </Text>
                  <Text>
                    Sie können sich in alle Richtungen umsehen und haben das Gefühl, tatsächlich vor Ort zu sein. Die Aufnahmen
                    für VR-Videos erfolgen mit speziellen Kameras, die in der Regel ein 360-Grad-Sichtfeld erfassen. Dadurch
                    können Sie sich in der virtuellen Umgebung umsehen und Details entdecken, als wären Sie wirklich dort.
                  </Text>
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem>
                <h2>
                  <AccordionButton>
                    <Box flex="1" textAlign="left" py={"2"} h={"full"}>
                      <Text fontSize={"md"}> </Text>
                      <Text color={"darkBlue.500"} fontWeight={600} fontSize={"large"}>
                        Anwendungen
                      </Text>
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <Text mb={2}>
                    Anwendungen sind speziell entwickelte Anwendungen, die für die Nutzung in der virtuellen Realität konzipiert
                    sind. Sie bieten eine Vielzahl von interaktiven und immersiven Erfahrungen, die über VR-Brillen erlebt werden
                    können.
                  </Text>
                  <Text mb={2}>
                    Es gibt verschiedene Arten von Anwendungen, die auf unterschiedliche Bedürfnisse und Interessen zugeschnitten
                    sind. Hier sind einige Beispiele:
                  </Text>
                  <Text mb={2}>
                    <Box fontWeight={600} color={"darkBlue.500"}>
                      Bildung und Training
                    </Box>
                    Anwendungen werden zunehmend im Bildungsbereich eingesetzt, um interaktive Lernumgebungen zu schaffen. Sie
                    können beispielsweise historische Ereignisse visualisieren, medizinische Simulationen ermöglichen oder
                    technische Fähigkeiten vermitteln. VR bietet eine praktische und immersive Möglichkeit, Wissen und
                    Fähigkeiten zu erlernen und zu trainieren.
                  </Text>
                  <Text mb={2}>
                    <Box fontWeight={600} color={"darkBlue.500"}>
                      Reisen und Erkunden
                    </Box>
                    Mit Anwendungen können Sie virtuelle Reisen unternehmen und Orte erkunden, die Sie möglicherweise nie
                    besuchen können. Sie können atemberaubende Landschaften erkunden, historische Stätten besichtigen oder in
                    ferne Länder eintauchen, ohne Ihr Zuhause zu verlassen.
                  </Text>
                  <Text mb={2}>
                    <Box fontWeight={600} color={"darkBlue.500"}>
                      Medien
                    </Box>
                    Andere Inhalte bieten auch Unterhaltungsangebote wie virtuelle Konzerte, 3D-Filme oder interaktive
                    Geschichten. Sie können sich in spannende Geschichten vertiefen, Musik in einer immersiven Umgebung genießen
                    oder Live-Veranstaltungen virtuell besuchen.
                  </Text>
                </AccordionPanel>
              </AccordionItem>
              <AccordionItem>
                <h2>
                  <AccordionButton>
                    <Box flex="1" textAlign="left" py={"2"} h={"full"}>
                      <Text fontSize={"md"}> </Text>
                      <Text color={"darkBlue.500"} fontWeight={600} fontSize={"large"}>
                        Web Apps
                      </Text>
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <Text mb={2}>
                    Es gibt auch Anwendungen, die direkt im Webbrowser verwendet werden können, diese können einfach über die
                    Inhaltsart &quot;Web App&quot; im Portal genutzt werden.
                  </Text>
                  <Text mb={2}>
                    Sie können direkt auf Websites zugreifen, die VR-Inhalte anbieten, und mit Hilfe der VR-Brille in die
                    virtuelle Realität einzutauchen.
                  </Text>

                  <Text mb={2}>
                    <Box color={"darkBlue.500"} fontWeight={600}>
                      Hinweise zur Nutzung einer Web App:
                    </Box>
                    Die Web App ist eine Anwendung, die über einen Server bereitgestellt und im Browser beliebiger Endgeräte (in
                    diesem Fall eine VR-Brille) abgerufen wird. Wir bitten daher Nutzerinnen und Nutzer beim Einsatz der Web App
                    folgende Hinweise zu beachten:
                  </Text>
                  <UnorderedList my={4}>
                    <ListItem mb={2}>
                      Der Zugriff bedarf einer stabilen Internetverbindung der jeweiligen VR-Brillen, um die Inhalte der Webseite
                      zu laden.
                    </ListItem>
                    <ListItem mb={2}>
                      Die Recherche der verwiesenen Inhalte erfolgte nach bestem Wissen und Gewissen. Für fehlerhafte,
                      unvollständige oder unangemessene Inhalte haftet aber allein der Anbieter der Seite, auf welche verwiesen
                      wurde und nicht die VIL GmbH.
                    </ListItem>
                    <ListItem mb={2}>
                      Da der Zugriff auf die Anwendung bzw. das Video über einen Browser erfolgt, haben Anwender*innen die
                      Möglichkeit über eine Änderung der URL andere Inhalte aufzurufen. Bitte beachten Sie dies beim Einsatz der
                      Anwendung (vor allem durch Minderjährige).
                    </ListItem>
                  </UnorderedList>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </>
        </AccordionPanel>
      </AccordionItem>
    ),
    ITEM_2: (
      <AccordionItem>
        <h2>
          <AccordionButton>
            <Box flex="1" textAlign="left" py={"2"} h={"full"}>
              <Text fontSize={"md"}> </Text>
              <Text color={"darkBlue.500"} fontWeight={600} fontSize={"large"}>
                Inhalte suchen
              </Text>
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4}>
          <Text>
            Zum Suchen von Inhalten stehen Ihnen mehrere Filtermöglichkeiten zur Verfügung. Nutzen Sie hierfür die Schaltflächen
            am Kopf der Mediathek.
          </Text>
          <SupportInfoImage chakraW={"lg"} imgSrc="/img/support/top_tag_area.png" imgText="Darstellung der Top Tags" />
          <UnorderedList my={4}>
            <ListItem mb={2}>Tags: </ListItem>
            <ListItem ml={8} mb={2}>
              Tags repräsentieren eine Zuordnung des Inhalts zu einem bestimmten Themenfeld. Einem Inhalt können bis zu drei Tags
              zugeordnet sein.
            </ListItem>
            <ListItem ml={8} mb={2}>
              Drücken Sie &quot;#Suche&quot; um die Auswahl der Tags zu öffnen
            </ListItem>
            <SupportInfoImage
              chakraW={"xl"}
              imgSrc="/img/support/tag_selection.png"
              imgText="Darstellung des Auswahlbereichs für Tags"
            />
            <ListItem ml={8} mb={2}>
              Wählen Sie nun einen oder mehrere Tags aus. Die Auswahl der Tags wird sich dann auf die noch zur Verfügung
              stehenden Tags reduzieren.
            </ListItem>
            <ListItem mb={2}>Inhaltstyp:</ListItem>
            <ListItem listStyleType={"none"} ml={8} mb={2}>
              Hier haben Sie die Möglichkeit, die Anzeige auf einen oder mehrere Inhaltstypen einzugrenzen.
            </ListItem>
            <ListItem mb={2}>VR-Brillen:</ListItem>
            <ListItem listStyleType={"none"} ml={8} mb={2}>
              Durch die Auswahl einer VR-Brille werden Inhalte angezeigt die mit mindestens einem der ausgewählten VR-Brillen
              kompatiblen sind.
            </ListItem>
            <ListItem listStyleType={"none"} ml={8} mb={2}>
              Das VIL Portal wählt für Sie automatisch Ihre Gerätetypen aus.
            </ListItem>
            <ListItem mb={2}>Quelle:</ListItem>
            <ListItem listStyleType={"none"} ml={8} mb={2}>
              Hier haben Sie die Möglichkeit, die Anzeige auf einen oder mehrere Quellen einzugrenzen.
            </ListItem>

            <ListItem listStyleType={"none"} ml={8} mb={2}></ListItem>
            <ListItem mb={2}>Suche</ListItem>
            <ListItem listStyleType={"none"} ml={8} mb={2}>
              Zusätzlich zu den Filtern können Sie auch durch Texteingabe nach Titel, Tag und Lizenzgeber suchen.
            </ListItem>
          </UnorderedList>
          <Text>Alle Filter sind abwählbar und können mithilfe der Löschen-Schaltfläche zurückgesetzt werden.</Text>
          <SupportInfoImage
            chakraW={"lg"}
            imgSrc="/img/support/filter_bar_delete.png"
            imgText="Darstellung des Filterbereichs mit Schaltfläche zum Löschen"
          />
          <Text mt={4}>
            Wenn Sie interessanten Inhalt entdecken, können Sie durch Anklicken des farblich hervorgehobenen Bereichs die
            Detailseite des Inhalts öffnen.
          </Text>
          <SupportInfoImage chakraW={"sm"} imgSrc="/img/support/example_content.png" imgText="Beispielhafter Inhalt" />

          <Text>
            In der detaillierten Beschreibung finden Sie zusätzliche Informationen über den Inhalt und können diesen einer Ihrer
            Wiedergabelisten hinzufügen.
          </Text>
        </AccordionPanel>
      </AccordionItem>
    ),
    ITEM_3: (
      <AccordionItem>
        <h2>
          <AccordionButton>
            <Box flex="1" textAlign="left" py={"2"} h={"full"}>
              <Text fontSize={"md"}> </Text>
              <Text color={"darkBlue.500"} fontWeight={600} fontSize={"large"}>
                Inhalte erstellen
              </Text>
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4}>
          <Text>
            Über die Schaltfläche &quot;Inhalt erstellen&quot; gelangen sie zu dem Formular, in dem Sie den gewünschten Inhalt
            anknüpfen und die relevanten Informationen wie Titel und Tags hinterlegen können.
          </Text>

          <Text my={2}>
            Informationen wie Kurzbeschreibung oder Vorschaubilder sind optional zu hinterlegen, jedoch empfehlenswert, da sie
            anderen Benutzern Ihrere Organisation wichtige Informationen vermitteln können.
          </Text>
          <Divider my={4} />
          <Text mb={2}>
            <Box color={"darkBlue.500"} fontWeight={600}>
              Wichtig:
            </Box>
            Wir können nicht für die Kompatibilität externer Anwendungen und Videos für alle VR-Brillen garantieren. Beachten Sie
            daher mögliche Anforderungen der Hersteller bzw. Lizenzgeber.
          </Text>
          <Divider my={4} />
          <Text>Erläuterung der Pflichtfelder:</Text>
          <UnorderedList my={4}>
            <ListItem mb={2}>Inhaltsart:</ListItem>
            <ListItem listStyleType={"none"} ml={8} mb={2}>
              Geben Sie an, ob es sich bei der hochzuladenen Datei um ein Video oder eine Anwendung handelt. Web Apps stehen
              Ihnen nun zur Verfügung werden.
            </ListItem>
            <ListItem mb={2}> Link zur Anwendung:</ListItem>
            <ListItem listStyleType={"none"} ml={8} mb={2}>
              Um eine Web App nutzen zu können, fügen Sie bitte einen öffentlich zugänglichen Link zu dieser hinzu.
            </ListItem>
            <ListItem mb={2}>Inhalt (Datei)</ListItem>
            <ListItem listStyleType={"none"} ml={8} mb={2}>
              Für Videos und Anwendungen wird eine Datei benötigt. Wählen Sie eine Datei aus, dessen Format mit der hinterlegten
              Inhaltsart übereinstimmt.
              <Text> Vorgesehen für Videos sind .mp4-Dateien und für Anwendungen .apk-Dateien. </Text>
            </ListItem>
            <ListItem mb={2}> Name des Inhalts:</ListItem>
            <ListItem listStyleType={"none"} ml={8} mb={2}>
              Ein eindeutiger Name hilft Ihnen bei der Identifizierung des Inhalts.
            </ListItem>
            <ListItem mb={2}>Tags:</ListItem>
            <ListItem listStyleType={"none"} ml={8} mb={2}>
              Mindestens eine Tag muss zugeordnet werden. Pro Inhalt können maximal drei Tags zugeordnet werden.
            </ListItem>

            <ListItem listStyleType={"none"}>Pflichtfelder sind mit * markiert.</ListItem>
          </UnorderedList>
          <Text mb={2}>Beachten Sie die Anforderungen an die jeweiligen Formate.</Text>
          <Text color={"darkBlue.500"} fontWeight={600}>
            Anforderungen an Videos:
          </Text>
          <UnorderedList ml={4} mb={4}>
            <ListItem ml={2}>Format: .mp4</ListItem>
            <ListItem ml={2}>Codierung auf H264</ListItem>
          </UnorderedList>
          <Text color={"darkBlue.500"} fontWeight={600}>
            Anforderungen an Anwendungen:
          </Text>
          <UnorderedList ml={4} mb={4}>
            <ListItem ml={2}>Format: .apk</ListItem>
            <ListItem ml={2} mb={2}>
              Im Falle einer .apk werden notwendige Metadaten automatisch hinzugefügt:
            </ListItem>
            <ListItem listStyleType={"none"} ml={4}>
              Packagename
            </ListItem>
            <ListItem listStyleType={"none"} ml={4}>
              Versionsnummer
            </ListItem>
          </UnorderedList>
          <Divider my={4} />
          <Text mt={4} color={"darkBlue.500"} fontWeight={600}>
            Optionale Angaben
          </Text>
          <UnorderedList my={4}>
            <ListItem mb={2}>Kurzbeschreibung: </ListItem>
            <ListItem listStyleType={"none"} ml={8} mb={2}>
              Fügen Sie eine kurze Beschreibung hinzu, welche im VIL Launcher verwendet werden soll.
            </ListItem>
            <ListItem mb={2}>Vollständige Beschreibung: </ListItem>
            <ListItem listStyleType={"none"} ml={8} mb={2}>
              Fügen Sie eine ausführliche Beschreibung hinzu, um den Inhalt zu erläutern.
            </ListItem>

            <ListItem mb={2}>Vorschaubilder</ListItem>
            <ListItem listStyleType={"none"} ml={8} mb={2}>
              Hier können bis zu vier Bilder hinzugefügt werden, um die Identifikation zu vereinfachen. Mindestens ein Bild ist
              jedoch empfehlenswert.
            </ListItem>
            <ListItem mb={2}>Videolänge</ListItem>
            <ListItem listStyleType={"none"} ml={8} mb={2}>
              Hier haben Sie die Möglichkeit, die Länge des Videos anzugeben.
            </ListItem>
            <ListItem mb={2}>Vorschaubilder</ListItem>
            <ListItem listStyleType={"none"} ml={8} mb={2}>
              Hier können bis zu vier Bilder hinzugefügt werden, um die Identifikation zu vereinfachen. Mindestens ein Bild ist
              jedoch empfehlenswert.
            </ListItem>
            <ListItem mb={2}>Kompatible VR-Brillen</ListItem>
            <ListItem listStyleType={"none"} ml={8} mb={2}>
              Hier haben Sie die Möglichkeit anzugeben, mit welchen VR-Brillen der Inhalt.
            </ListItem>
            <ListItem mb={2}>Sprache(n)</ListItem>
            <ListItem listStyleType={"none"} ml={8} mb={2}>
              Hier haben Sie die Möglichkeit die Sprache(n) des Inhalts anzugeben.
            </ListItem>
            <ListItem mb={2}>Alterempfehlung</ListItem>
            <ListItem listStyleType={"none"} ml={8} mb={2}>
              Hier haben Sie die Möglichkeit die Alterempfehlung des Inhalts anzugeben. Hierfür können Sie wahlsweise einen der
              beiden Werte befüllen oder beide.
            </ListItem>
            <ListItem mb={2}>Lizenzgeber:</ListItem>
            <ListItem listStyleType={"none"} ml={8} mb={2}>
              Hier haben Sie die Möglichkeit, den Namen und einen Link zum Lizenzgeber des Inhalts anzugeben.
            </ListItem>
          </UnorderedList>
          <Text mb={2}>
            <Box color={"darkBlue.500"} fontWeight={600}>
              Hinweis:
            </Box>
            Um mehrere Dateien hochladen zu können, müssen alle gewünschten Dateien in einem Schritt in dem sich öffnendem Dialog
            ausgewählt werden.
          </Text>
          <Text mt={4} color={"darkBlue.500"} fontWeight={600}>
            Anforderungen an Vorschaubilder
          </Text>
          <UnorderedList my={4}>
            <ListItem mb={2}>Dateigröße: max. 100 kb </ListItem>
            <ListItem mb={2}>Format(e): .png, .jpg </ListItem>
          </UnorderedList>
          <Text mt={4} color={"darkBlue.500"} fontWeight={600}>
            Empfehlungen an Vorschaubilder
          </Text>
          <UnorderedList my={4}>
            <ListItem mb={2}> Mindestgröße: Höhe 459 px und Breite 688 px</ListItem>
            <ListItem mb={2}> Format: (3:4) </ListItem>
          </UnorderedList>
          <Text>
            Nachdem Sie die gewünschte Datei hinterlegt und die Felder in dem Formular ausgefühlt haben, betätigen Sie die
            &quot;Erstellen&quot; Schaltfläche, um mit dem Speichern des Inhalts zu beginnen.
          </Text>
        </AccordionPanel>
      </AccordionItem>
    ),
    ITEM_4: (
      <AccordionItem>
        <h2>
          <AccordionButton>
            <Box flex="1" textAlign="left" py={"2"} h={"full"}>
              <Text fontSize={"md"}> </Text>
              <Text color={"darkBlue.500"} fontWeight={600} fontSize={"large"}>
                Inhalte bearbeiten
              </Text>
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4}>
          <Text mb={2}>
            Um die Angaben zu den eigenen Inhalten zu ändern oder zu ergänzen, suchen Sie zunächst den gewünschte Inhalt in der
            Mediathek.
          </Text>
          <Text mb={2}>
            Wechseln Sie durch das Anklicken der Inhaltskachel zur Detail-Ansicht und öffnen Sie mit Hilfe der Schaltfläche
            &quot;Bearbeiten&quot; das Formular zur Bearbeitung des Inhalts.
          </Text>
          <Text>
            Ändern Sie die nun die gewünschten Informationen des Inhalts und speichern Ihre Änderungen mit der Schaltfläche
            &quot;Aktualisieren&quot; am Endes des Formulars.
          </Text>
        </AccordionPanel>
      </AccordionItem>
    ),
    ITEM_5: (
      <AccordionItem>
        <h2>
          <AccordionButton>
            <Box flex="1" textAlign="left" py={"2"} h={"full"}>
              <Text fontSize={"md"}> </Text>
              <Text color={"darkBlue.500"} fontWeight={600} fontSize={"large"}>
                Inhalte löschen
              </Text>
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4}>
          <Text mb={2}>
            Um einen hochgeladenen Inhalt aus der Mediathek zu entfernen, suchen Sie zunächst den gewünschte Inhalt in der
            Mediathek.
          </Text>
          <Text mb={2}>
            Wechseln Sie durch das Anklicken der Inhaltskachel zur Detail-Ansicht und öffnen Sie mit Hilfe der Schaltfläche
            &quot;Bearbeiten&quot; das Formular zur Bearbeitung des Inhalts.
          </Text>
          <Text>
            Die Schaltfläche zur Löschung des Inhaltes befindet sich neben der Schaltfläche &quot;Aktualisieren&quot;. Durch das
            Betätigen der Schaltfläche &quot;Inhalt Löschen&quot; schließen Sie den Löschvorgang ab.
          </Text>

          <Text my={2} fontWeight={600} color={"darkBlue.500"}>
            Wichtig:
          </Text>
          <Text> Gelöschte Inhalte können nicht wieder hergestellt werden.</Text>
          <OrderedList>
            <ListItem listStyleType={"none"}></ListItem>
          </OrderedList>
        </AccordionPanel>
      </AccordionItem>
    )
  },
  PRODUKTVERWALTUNG: {
    SUB_ITEM_1: (
      <AccordionItem>
        <h2>
          <AccordionButton>
            <Box flex="1" textAlign="left" py={"2"} h={"full"}>
              <Text color={"darkBlue.500"} fontWeight={600} fontSize={"large"}>
                Mehrere Steuern
              </Text>
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4}>
          <Text>
            Vor der Benutzung Ihres VIL Produktes (Lernwagen/Lernpult/Lernkoffer) sollten Sie über
            <Link mx={1} display={"inline-block"} color={"darkBlue.500"} fontWeight={600} isExternal={true} href={"/products"}>
              diese Seite
            </Link>
            prüfen ob alle VR-Brillen korrekt verbunden sind. Wählen Sie hierfür die VR-Brillen des gewünschten Produktes aus.
            Brillen die als &quot;Offline&quot; markiert sind werden nicht vom Portal für Befehle (bspw. Synchronisation oder
            Inhalt starten) berücksichtigt.
          </Text>
          <SupportInfoImage chakraW={"lg"} imgSrc="/img/support/example_device.png" imgText={"Darstellung VR-Brille"} />
          <Text>Über diese Seite erreichen Sie auch die wichtigstens Funktionen für die Verwaltung der VR-Brillen.</Text>
          <Text>Folgende Optionen stehen Ihnen für alle VR-Brillen zu Verfügung</Text>
          <UnorderedList my={4}>
            <ListItem mb={2}> Ausschalten:</ListItem>
            <ListItem listStyleType={"none"} ml={8} mb={2}>
              Um alle VR-Brillen am Ende der Benutzung herunterzufahren, haben Sie über das Portal die Möglichkeit sämtlichen
              VR-Brillen den Befehl zum Herunterfahren zu geben. Dieser Befehl wird auch bei aktiver Nutzung die Brille
              ausgeführt.
            </ListItem>
            <ListItem mb={2}>Synchronisieren:</ListItem>
            <ListItem listStyleType={"none"} ml={8} mb={2}>
              Spielt alle Inhalte Ihrer Wiedergabeliste auf die aktiven VR-Brillen auf. Sind alle Inhalte synchronisiert, wird
              die Brille automatisch neugestartet. Wenn Aktualisierungen der Betriebssoftware verfügbar sind, wird dieses Update
              automatisch auf die Brille aufgespielt.
            </ListItem>
            <ListItem mb={2}>Geführten Modus umschalten:</ListItem>
            <ListItem listStyleType={"none"} ml={8} mb={2}>
              Aktiviert oder Deaktiviert den geführten Modus. Im geführten Modus haben die Träger der VR-Brillen keine
              Möglichkeit, eigenständig Inhalte zu starten oder zu stoppen.
            </ListItem>
            <Text mb={2}>
              <Box color={"darkBlue.500"} fontWeight={600}>
                Hinweis:
              </Box>
              Bitte überprüfen Sie, ob alle Geräte als online angezeigt werden, bevor Sie den geführten Modus aktivieren.
              Beachten Sie, dass der geführte Modus ein temporärer Zustand der VR-Brille ist. Wenn die Brille ausgeschaltet ist
              oder zum Zeitpunkt des Befehls nicht eingeschaltet ist, befindet sie sich nicht im geführten Modus.
            </Text>
          </UnorderedList>
        </AccordionPanel>
      </AccordionItem>
    ),
    SUB_ITEM_2: (
      <AccordionItem>
        <h2>
          <AccordionButton>
            <Box flex="1" textAlign="left" py={"2"} h={"full"}>
              <Text fontSize={"md"}> </Text>
              <Text color={"darkBlue.500"} fontWeight={600} fontSize={"large"}>
                Einzelne steuern
              </Text>
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4}>
          <Box mb={4}>
            <Text>
              Um eine einzelne VR-Brille zu steuern, empfehlen wir den Zugriff auf die Detailseite. Diese erreichen Sie über die
              &quot;Details&quot;-Schaltfläche.
            </Text>
          </Box>
          <Text>Folgende Angaben finden Sie auf der Detailseite der Brille:</Text>
          <UnorderedList my={4}>
            <ListItem mb={2}>Belegter / Freier Speicher</ListItem>
            <ListItem mb={2}>Seriennummer</ListItem>
            <ListItem mb={2}>Aktueller / Letzter Akkustand</ListItem>
            <ListItem mb={2}>Verwendete Wiedergabeliste</ListItem>
            <ListItem mb={2}>
              Aktuell synchronisierte Inhalte auf der Brille, als auch eine Möglichkeit diese zu starten.
            </ListItem>
          </UnorderedList>
          <Text>
            Darüber hinaus stehen Ihnen auf der Detailseite alle Funktionen zur Verfügung, die auch für alle VR-Brillen vorhanden
            sind.
          </Text>
        </AccordionPanel>
      </AccordionItem>
    ),
    SUB_ITEM_3: (
      <AccordionItem>
        <h2>
          <AccordionButton>
            <Box flex="1" textAlign="left" py={"2"} h={"full"}>
              <Text fontSize={"md"}> </Text>
              <Text color={"darkBlue.500"} fontWeight={600} fontSize={"large"}>
                Screencast
              </Text>
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4}>
          <Text>
            Das Screencast-Feature des VIL-Portals ermöglicht es Ihnen, das zu sehen, was der Benutzer der ausgewählten VR-Brille
            gerade sieht.
          </Text>
          <Text>
            Sie können das Screencast-Feature starten, indem Sie auf die &quot;Screencast&quot;-Schaltfläche für eine einzelne
            Brille klicken. Diese wird Ihnen nur angezeigt, wenn der Brillen-Typ dies unterstützt (für die PICO G24K besteht
            keine Kompabilität)
          </Text>
          <Text mb={2}>
            <Box color={"darkBlue.500"} fontWeight={600}>
              Hinweis:
            </Box>
            Um den Screencast nutzen zu können, ist es erforderlich, dass das aktuelle Gerät, auf dem Sie das Portal verwenden,
            mit dem Netzwerk Ihres VIL-Produkts verbunden ist.
          </Text>
          <Text>Um die Routerdaten (Netzwerkname und Passwort) einzusehen, verwenden Sie bitte die Produkt-Seite.</Text>
          <SupportInfoImage chakraW={"md"} imgSrc="/img/support/example_sdm.png" imgText={"Produkte mit zugeordnetem Router"} />
          <Text>Sind Sie nicht mit dem Netzwerk verbunden kann die Adresse nicht gefunden werden.</Text>
        </AccordionPanel>
      </AccordionItem>
    ),
    SUB_ITEM_4: (
      <AccordionItem>
        <h2>
          <AccordionButton>
            <Box flex="1" textAlign="left" py={"2"} h={"full"}>
              <Text color={"darkBlue.500"} fontWeight={600} fontSize={"large"}>
                Synchronisieren
              </Text>
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4}>
          <Text>
            Um Änderungen an Ihrer Wiedergabelisten auf Ihre VR-Brillen zu übertragen, müssen die Inhalt synchronisiert werden.
            Hierzu ist erforderlich, dass die Brillen angeschaltet sind und eine Internetverbindung haben. Darüber hinaus stellen
            Sie sicher das Sie die richtige Wiedergabeliste aktiviert haben.
          </Text>
          <Text>Es gibt mehrere Möglichkeiten Ihre Brillen zu synchronisieren.</Text>
          <Box my={4}>
            <Text mb={2} color={"darkBlue.500"} fontWeight={600}>
              Alle VR-Brillen über &quot;VR-Brillen&quot;
            </Text>
            <OrderedList>
              <ListItem>Navigieren Sie zu den &quot;VR-Brillen&quot; unter &quot;Produktverwaltung&quot;.</ListItem>
              <ListItem>
                Stellen Sie sicher, dass alle VR-brillen korrekt verbunden sind und betätigen die Schaltfläche
                &quot;Synchronisieren&quot;.
              </ListItem>

              <ListItem>
                Als nächstes werden Sie nacheinander gebeten zuerst die Inhalte und dann die Brillen des Prozesses zu bestätigen.
              </ListItem>
              <ListItem>Nach Bestätigung startet die Warteschlange für Ihre Brillen.</ListItem>
              <Box>
                <Text fontWeight={600} color={"darkBlue.500"}>
                  Wichtig:
                </Text>
                Bitte verlassen Sie die Seite während der Warteschlange nicht. Diese wird den Prozess nach der Synchronisation
                der letzten VR-Brille selbstständig beenden.
              </Box>
              <ListItem>
                VR-Brillen, welche aufgrund von veralteter Betriebssoftware nicht für die Warteschlange qualifiziert sind, werden
                nach einem veralteten Verfahren synchronisiert. Diese Brillen erhalten erst nach Beendigung der Warteschlange den
                Synchronisationsbefehl.Im Anschluss an die Synchronisation erfolgt ein automatischer Neustart.
              </ListItem>
            </OrderedList>
          </Box>
          <Box my={4}>
            <Text mb={2} color={"darkBlue.500"} fontWeight={600}>
              Einzelne VR-Brillen über &quot;Details&quot;
            </Text>
            <OrderedList>
              <ListItem>
                Zuerst navigieren Sie auf die Detailseite der VR-Brille. Hierzu benutzen Sie die
                &quot;Details&quot;-Schaltfläche.
              </ListItem>
              <ListItem>
                Auf der Detailseite können Sie - insofern eine Synchronisierung notwendig ist - mit Hilfe der
                &quot;Synchronisieren&quot; Schaltfläche den Prozess starten.
              </ListItem>
              <ListItem>Nach Bestätigung startet die Synchronisation für Ihre Brille.</ListItem>

              <Text fontWeight={600} color={"darkBlue.500"}>
                Wichtig:
              </Text>

              <ListItem>
                Ist Ihre Brille aufgrund von veralteter Betriebssoftware nicht für die Warteschlange qualifiziert, wird sie nach
                einem veralteten Verfahren synchronisiert. Bei dieser Brille müssen sie auf einen Neustart des Gerätes warten,
                nachdem Sie den Befehl gesendet haben.
              </ListItem>
            </OrderedList>
          </Box>
        </AccordionPanel>
      </AccordionItem>
    ),
    INDEX: (
      <>
        <Text pb={4}>
          In dem Bereich &quot;Produkte&quot; sehen Sie ihre Produkte. Hier können Sie ihr Produkt auswählen, um mit dessen
          VR-Brillen via der gleichnamigen Schaltfläche fortzufahren.
        </Text>
        <Text pb={4}>
          Wenn Sie VR-Brillen von mehrere Produkten benutzen wollen, können Sie mithilfe der &quot;Mehrfachauswahl&quot; den
          Auswahlmodus aktivieren und ein oder mehrere Produkte zur Nutzung auswählen. Danach können Sie mit der
          &quot;VR-Brillen&quot;-Schaltfläche zu den VR-Brillen aller ausgewählten Produkte navigieren.
        </Text>
        <SupportInfoImage chakraW={"xl"} imgSrc="/img/support/products_page.png" imgText="Beispielhafte Produkte-Seite" />
      </>
    ),
    INTRO: (
      <Text pb={4}>
        Die Produktverwaltung gibt Ihnen Zugriff auf Ihre Produkte (Lernwagen/-koffer/-pult) , von hier aus erreichen Sie Ihre
        VR-Brillen als auch Ihre Wiedergabelisten.
      </Text>
    ),
    ITEM_1: (
      <Accordion allowToggle reduceMotion>
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box flex="1" textAlign="left" py={"2"} h={"full"}>
                <Text color={"darkBlue.500"} fontWeight={600} fontSize={"large"}>
                  Produkte
                </Text>
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <>
              <Text pb={4}>
                In dem Bereich &quot;Produkte&quot; sehen Sie ihre Produkte. Hier können Sie ihr Produkt auswählen, um mit dessen
                VR-Brillen via der gleichnamigen Schaltfläche fortzufahren.
              </Text>
              <Text pb={4}>
                Wenn Sie VR-Brillen von mehrere Produkten benutzen wollen, können Sie mithilfe der &quot;Mehrfachauswahl&quot;
                den Auswahlmodus aktivieren und ein oder mehrere Produkte zur Nutzung auswählen. Danach können Sie mit der
                &quot;VR-Brillen&quot;-Schaltfläche zu den VR-Brillen aller ausgewählten Produkte navigieren.
              </Text>
              <SupportInfoImage chakraW={"xl"} imgSrc="/img/support/products_page.png" imgText="Beispielhafte Produkte-Seite" />
              <Accordion allowToggle reduceMotion>
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box flex="1" textAlign="left" py={"2"} h={"full"}>
                        <Text color={"darkBlue.500"} fontWeight={600} fontSize={"large"}>
                          VR-Brillen
                        </Text>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <>
                      <Accordion allowToggle reduceMotion>
                        <AccordionItem>
                          <h2>
                            <AccordionButton>
                              <Box flex="1" textAlign="left" py={"2"} h={"full"}>
                                <Text color={"darkBlue.500"} fontWeight={600} fontSize={"large"}>
                                  Mehrere Steuern
                                </Text>
                              </Box>
                              <AccordionIcon />
                            </AccordionButton>
                          </h2>
                          <AccordionPanel pb={4}>
                            <Text>
                              Vor der Benutzung Ihres VIL Produktes (Lernwagen/Lernpult/Lernkoffer) sollten Sie über
                              <Link
                                mx={1}
                                display={"inline-block"}
                                color={"darkBlue.500"}
                                fontWeight={600}
                                isExternal={true}
                                href={"/products"}
                              >
                                diese Seite
                              </Link>
                              prüfen ob alle VR-Brillen korrekt verbunden sind. Wählen Sie hierfür die VR-Brillen des gewünschten
                              Produktes aus. Brillen die als &quot;Offline&quot; markiert sind werden nicht vom Portal für
                              Befehle (bspw. Synchronisation oder Inhalt starten) berücksichtigt.
                            </Text>
                            <SupportInfoImage
                              chakraW={"lg"}
                              imgSrc="/img/support/example_device.png"
                              imgText={"Darstellung VR-Brille"}
                            />
                            <Text>
                              Über diese Seite erreichen Sie auch die wichtigstens Funktionen für die Verwaltung der VR-Brillen.
                            </Text>
                            <Text>Folgende Optionen stehen Ihnen für alle VR-Brillen zu Verfügung</Text>
                            <UnorderedList my={4}>
                              <ListItem mb={2}> Ausschalten:</ListItem>
                              <ListItem listStyleType={"none"} ml={8} mb={2}>
                                Um alle VR-Brillen am Ende der Benutzung herunterzufahren, haben Sie über das Portal die
                                Möglichkeit sämtlichen VR-Brillen den Befehl zum Herunterfahren zu geben. Dieser Befehl wird auch
                                bei aktiver Nutzung die Brille ausgeführt.
                              </ListItem>
                              <ListItem mb={2}>Synchronisieren:</ListItem>
                              <ListItem listStyleType={"none"} ml={8} mb={2}>
                                Spielt alle Inhalte Ihrer Wiedergabeliste auf die aktiven VR-Brillen auf. Sind alle Inhalte
                                synchronisiert, wird die Brille automatisch neugestartet. Wenn Aktualisierungen der
                                Betriebssoftware verfügbar sind, wird dieses Update automatisch auf die Brille aufgespielt.
                              </ListItem>
                              <ListItem mb={2}>Geführten Modus umschalten:</ListItem>
                              <ListItem listStyleType={"none"} ml={8} mb={2}>
                                Aktiviert oder Deaktiviert den geführten Modus. Im geführten Modus haben die Träger der
                                VR-Brillen keine Möglichkeit, eigenständig Inhalte zu starten oder zu stoppen.
                              </ListItem>

                              <Text mb={2}>
                                <Box color={"darkBlue.500"} fontWeight={600}>
                                  Hinweis:
                                </Box>
                                Bitte überprüfen Sie, ob alle Geräte als online angezeigt werden, bevor Sie den geführten Modus
                                aktivieren. Beachten Sie, dass der geführte Modus ein temporärer Zustand der VR-Brille ist. Wenn
                                die Brille ausgeschaltet ist oder zum Zeitpunkt des Befehls nicht eingeschaltet ist, befindet sie
                                sich nicht im geführten Modus.
                              </Text>
                            </UnorderedList>
                          </AccordionPanel>
                        </AccordionItem>
                        <AccordionItem>
                          <h2>
                            <AccordionButton>
                              <Box flex="1" textAlign="left" py={"2"} h={"full"}>
                                <Text fontSize={"md"}> </Text>
                                <Text color={"darkBlue.500"} fontWeight={600} fontSize={"large"}>
                                  Einzelne steuern
                                </Text>
                              </Box>
                              <AccordionIcon />
                            </AccordionButton>
                          </h2>
                          <AccordionPanel pb={4}>
                            <Box mb={4}>
                              <Text>
                                Um eine einzelne VR-Brille zu steuern, empfehlen wir den Zugriff auf die Detailseite. Diese
                                erreichen Sie über die &quot;Details&quot;-Schaltfläche.
                              </Text>
                            </Box>
                            <Text>Folgende Angaben finden Sie auf der Detailseite der Brille:</Text>
                            <UnorderedList my={4}>
                              <ListItem mb={2}>Belegter / Freier Speicher</ListItem>
                              <ListItem mb={2}>Seriennummer</ListItem>
                              <ListItem mb={2}>Aktueller / Letzter Akkustand</ListItem>
                              <ListItem mb={2}>Verwendete Wiedergabeliste</ListItem>
                              <ListItem mb={2}>
                                Aktuell synchronisierte Inhalte auf der Brille, als auch eine Möglichkeit diese zu starten.
                              </ListItem>
                            </UnorderedList>
                            <Text>
                              Darüber hinaus stehen Ihnen auf der Detailseite alle Funktionen zur Verfügung, die auch für alle
                              VR-Brillen vorhanden sind.
                            </Text>
                          </AccordionPanel>
                        </AccordionItem>
                        <AccordionItem>
                          <h2>
                            <AccordionButton>
                              <Box flex="1" textAlign="left" py={"2"} h={"full"}>
                                <Text fontSize={"md"}> </Text>
                                <Text color={"darkBlue.500"} fontWeight={600} fontSize={"large"}>
                                  Screencast
                                </Text>
                              </Box>
                              <AccordionIcon />
                            </AccordionButton>
                          </h2>
                          <AccordionPanel pb={4}>
                            <Text>
                              Das Screencast-Feature des VIL-Portals ermöglicht es Ihnen, das zu sehen, was der Benutzer der
                              ausgewählten VR-Brille gerade sieht.
                            </Text>
                            <Text>
                              Sie können das Screencast-Feature starten, indem Sie auf die &quot;Screencast&quot;-Schaltfläche
                              für eine einzelne Brille klicken. Diese wird Ihnen nur angezeigt, wenn der Brillen-Typ dies
                              unterstützt (für die PICO G24K besteht keine Kompabilität)
                            </Text>
                            <Text mb={2}>
                              <Box color={"darkBlue.500"} fontWeight={600}>
                                Hinweis:
                              </Box>
                              Um den Screencast nutzen zu können, ist es erforderlich, dass das aktuelle Gerät, auf dem Sie das
                              Portal verwenden, mit dem Netzwerk Ihres VIL-Produkts verbunden ist.
                            </Text>
                            <Text>
                              Um die Routerdaten (Netzwerkname und Passwort) einzusehen, verwenden Sie bitte die Produkt-Seite.
                            </Text>
                            <SupportInfoImage
                              chakraW={"md"}
                              imgSrc="/img/support/example_sdm.png"
                              imgText={"Produkte mit zugeordnetem Router"}
                            />
                            <Text>Sind Sie nicht mit dem Netzwerk verbunden kann die Adresse nicht gefunden werden.</Text>
                          </AccordionPanel>
                        </AccordionItem>
                        <AccordionItem>
                          <h2>
                            <AccordionButton>
                              <Box flex="1" textAlign="left" py={"2"} h={"full"}>
                                <Text color={"darkBlue.500"} fontWeight={600} fontSize={"large"}>
                                  Synchronisieren
                                </Text>
                              </Box>
                              <AccordionIcon />
                            </AccordionButton>
                          </h2>
                          <AccordionPanel pb={4}>
                            <Text>
                              Um Änderungen an Ihrer Wiedergabelisten auf Ihre VR-Brillen zu übertragen, müssen die Inhalt
                              synchronisiert werden. Hierzu ist erforderlich, dass die Brillen angeschaltet sind und eine
                              Internetverbindung haben. Darüber hinaus stellen Sie sicher das Sie die richtige Wiedergabeliste
                              aktiviert haben.
                            </Text>
                            <Text>Es gibt mehrere Möglichkeiten Ihre Brillen zu synchronisieren.</Text>
                            <Box my={4}>
                              <Text mb={2} color={"darkBlue.500"} fontWeight={600}>
                                Alle VR-Brillen über &quot;VR-Brillen&quot;
                              </Text>
                              <OrderedList>
                                <ListItem>
                                  Navigieren Sie zu den &quot;VR-Brillen&quot; unter &quot;Produktverwaltung&quot;.
                                </ListItem>
                                <ListItem>
                                  Stellen Sie sicher, dass alle VR-brillen korrekt verbunden sind und betätigen die Schaltfläche
                                  &quot;Synchronisieren&quot;.
                                </ListItem>

                                <ListItem>
                                  Als nächstes werden Sie nacheinander gebeten zuerst die Inhalte und dann die Brillen des
                                  Prozesses zu bestätigen.
                                </ListItem>
                                <ListItem>Nach Bestätigung startet die Warteschlange für Ihre Brillen.</ListItem>
                                <Box>
                                  <Text fontWeight={600} color={"darkBlue.500"}>
                                    Wichtig:
                                  </Text>
                                  Bitte verlassen Sie die Seite während der Warteschlange nicht. Diese wird den Prozess nach der
                                  Synchronisation der letzten VR-Brille selbstständig beenden.
                                </Box>
                                <ListItem>
                                  VR-Brillen, welche aufgrund von veralteter Betriebssoftware nicht für die Warteschlange
                                  qualifiziert sind, werden nach einem veralteten Verfahren synchronisiert. Diese Brillen
                                  erhalten erst nach Beendigung der Warteschlange den Synchronisationsbefehl.Im Anschluss an die
                                  Synchronisation erfolgt ein automatischer Neustart.
                                </ListItem>
                              </OrderedList>
                            </Box>
                            <Box my={4}>
                              <Text mb={2} color={"darkBlue.500"} fontWeight={600}>
                                Einzelne VR-Brillen über &quot;Details&quot;
                              </Text>
                              <OrderedList>
                                <ListItem>
                                  Zuerst navigieren Sie auf die Detailseite der VR-Brille. Hierzu benutzen Sie die
                                  &quot;Details&quot;-Schaltfläche.
                                </ListItem>
                                <ListItem>
                                  Auf der Detailseite können Sie - insofern eine Synchronisierung notwendig ist - mit Hilfe der
                                  &quot;Synchronisieren&quot; Schaltfläche den Prozess starten.
                                </ListItem>
                                <ListItem>Nach Bestätigung startet die Synchronisation für Ihre Brille.</ListItem>

                                <Text fontWeight={600} color={"darkBlue.500"}>
                                  Wichtig:
                                </Text>

                                <ListItem>
                                  Ist Ihre Brille aufgrund von veralteter Betriebssoftware nicht für die Warteschlange
                                  qualifiziert, wird sie nach einem veralteten Verfahren synchronisiert. Bei dieser Brille müssen
                                  sie auf einen Neustart des Gerätes warten, nachdem Sie den Befehl gesendet haben.
                                </ListItem>
                              </OrderedList>
                            </Box>
                          </AccordionPanel>
                        </AccordionItem>
                      </Accordion>
                    </>
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
            </>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    ),
    ITEM_2: (
      <AccordionItem>
        <h2>
          <AccordionButton>
            <Box flex="1" textAlign="left" py={"2"} h={"full"}>
              <Text color={"darkBlue.500"} fontWeight={600} fontSize={"large"}>
                Wiedergabelisten
              </Text>
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4}>
          <Text>
            Über den Menüpunkt
            <Link
              mx={1}
              display={"inline-block"}
              color={"darkBlue.500"}
              fontWeight={600}
              isExternal={true}
              href={"/configurations"}
            >
              Wiedergabelisten
            </Link>
            haben Sie die Möglichkeit die Ihrer Organisation zugeordneten Wiedergabelisten einzusehen und neue zu erstellen.
          </Text>
          <SupportInfoImage imgSrc="/img/support/configurations_index.png" imgText={"Übersicht der Wiedergabelisten"} />

          <Text>
            In der Übersicht können Sie den Titel, die Art und Anzahl der Inhalte sehen, den Aktitätsstauts der Wiedergabeliste
            einsehen. Im Fall von einer aktiven Wiedergabeliste werden außerdem die zugeordneten Produkte als auch die Anzahl der
            VR-Brillen, denen die Wiedergabeliste zugeordnet ist angezeigt. Wiedergabelisten, die keiner VR-Brille zugeordnet
            sind, werden als &quot;Ungenutzt&quot; bezeichnet.
          </Text>
          <SupportInfoImage imgSrc="/img/support/active_configuration.png" imgText={"Beispielhafte Wiedergabeliste"} />

          <>
            <Accordion allowToggle reduceMotion>
              <AccordionItem>
                <h2>
                  <AccordionButton>
                    <Box flex="1" textAlign="left" py={"2"} h={"full"}>
                      <Text fontSize={"md"}> </Text>
                      <Text color={"darkBlue.500"} fontWeight={600} fontSize={"large"}>
                        Erstellen / Bearbeiten
                      </Text>
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <Text>Für die Erstellung oder Bearbeitung einer Wiedergabeliste bieten wir Ihnen zwei Optionen:</Text>
                  <UnorderedList my={4}>
                    <ListItem mb={2}> Wiedergabeliste erstellen:</ListItem>
                    <ListItem listStyleType={"none"} ml={8} mb={2}>
                      Wählen Sie diese Option, um eine neue Wiedergabeliste zu erstellen. Sie können dann Inhalte hinzufügen und
                      bei Bedarf die Wiedergabeliste aktivieren.
                    </ListItem>
                    <ListItem mb={2}>Bearbeiten:</ListItem>
                    <ListItem listStyleType={"none"} ml={8} mb={2}>
                      Wenn Sie eine bestehende Wiedergabeliste bearbeiten möchten, wählen Sie die
                      &quot;Bearbeiten&quot;-Schaltfläche auf der Detailseite aus. Sie können dann Inhalte entfernen, weitere
                      Inhalte hinzufügen, die Wiedergabeliste löschen oder auch duplizieren.
                    </ListItem>
                  </UnorderedList>

                  <Text>
                    Unabhängig Ihrer Auswahl können Sie innerhalb von 4 Schritten eine Wiedergabeliste erstellen und für die
                    Synchronisation aktivieren.
                  </Text>

                  <SupportInfoImage
                    chakraW={"xl"}
                    imgSrc="/img/support/edit_configuration_page_1.png"
                    imgText={"Seite 1: Titel festlegen"}
                  />

                  <Text>
                    Als erstes muss ein Titel für die Wiedergabeliste angegeben werden, dieser muss mindestens 4 und maximal 60
                    Zeichen lang sein.
                  </Text>
                  <SupportInfoImage
                    chakraW={"xl"}
                    imgSrc="/img/support/create_config_page_2_selected_content.png"
                    imgText={"Vergleich: Ausgewählt / Nicht ausgewählt"}
                  />
                  <Text>
                    Im zweiten Schritt können sie die Inhalte für die Wiedergabeliste auswählen. Dieser können durch klicken auf
                    das Bild oder die dafür vorgesehene Schaltfläche ausgewählt werden.
                  </Text>
                  <Text>
                    Die Inhalte sind in die Inhaltsarten unterteilt und können mithilfe der Textsuche wie auch in der Mediathek
                    durchsucht werden.
                  </Text>
                  <SupportInfoImage
                    chakraW={"xl"}
                    imgSrc="/img/support/create_config_page_2.png"
                    imgText={"Seite 2: Inhalte auswählen"}
                  />

                  <Text>
                    Nachdem Sie alle gewünschten Inhalte ausgewählt haben, können Sie nun entscheiden ob Sie die Wiedergabeliste
                    aktivieren wollen.
                  </Text>
                  <Text>
                    Um eine Wiedergabeliste zu aktivieren müssen Sie die Schaltfläche dafür aktivieren und eins oder mehrere
                    Ihrer Produkte auswählen.
                  </Text>
                  <Text>
                    Wird eine Wiedergabeliste beim Speichern aktiviert, so wird diese allen VR-Brillen des/der ausgewählten
                    Produkte/n zugeordnet.
                  </Text>

                  <SupportInfoImage
                    chakraW={"xl"}
                    imgSrc="/img/support/create_config_page_3.png"
                    imgText={"Seite 3: Wiedergabeliste aktivieren"}
                  />
                  <Text>
                    Abschließend erhalten Sie eine Zusammenfassung der bestehenden Wiedergabeliste. Sie sehen dort den Titel, ob
                    die Wiedergabeliste aktiviert wird und welche Inhalte vorhanden sind.
                  </Text>
                  <Text>
                    Hier haben Sie nochmal die Möglichkeit ggf. ungwünschte Inhalte zu entfernen. Diese Oberfläche kann beim
                    Bearbeiten auch zum Löschen von Inhalten aus der Wiedergabeliste benutzt werden.
                  </Text>
                  <SupportInfoImage
                    chakraW={"xl"}
                    imgSrc="/img/support/edit_configuration_page_4.png"
                    imgText={"Seite 4: Zusammenfassung / Inhalte entfernen"}
                  />
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem>
                <h2>
                  <AccordionButton>
                    <Box flex="1" textAlign="left" py={"2"} h={"full"}>
                      <Text fontSize={"md"}> </Text>
                      <Text color={"darkBlue.500"} fontWeight={600} fontSize={"large"}>
                        Duplizieren
                      </Text>
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <Text>
                    Damit Sie nicht bestehende Wiedergabeliste abändern müssen und ggf. mehrere redundante Inhalt auswählen
                    müssen, haben Sie die Möglichkeit eine bestehende Wiedergabeliste zu duplizieren.
                  </Text>
                  <Text>
                    Um eine Wiedergabeliste zu duplizieren, müssen Sie die Bearbeiten-Seite einer Wiedergabeliste aufrufen.
                  </Text>
                  <SupportInfoImage
                    chakraW={"xl"}
                    imgSrc="/img/support/edit_configuration_page_1.png"
                    imgText={"Beispielhafte Bearbeiten-Seite"}
                  />
                  <Text>
                    Am Kopf der Seite finden Sie die &quot;Duplizieren&quot; Schaltfläche. Klicken Sie diese um das
                    Dialog-Fenster zu öffnen.
                  </Text>
                  <Text>
                    In diesem ist dsie einzige notwendige Angabe der Titel für die neue Wiedergabeliste. Bitte beachten Sie das
                    ungespeicherte Änderungen für das duplizieren der Wiedergabeliste nicht berücksichtigt werden.
                  </Text>
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem>
                <h2>
                  <AccordionButton>
                    <Box flex="1" textAlign="left" py={"2"} h={"full"}>
                      <Text fontSize={"md"}> </Text>
                      <Text color={"darkBlue.500"} fontWeight={600} fontSize={"large"}>
                        Löschen
                      </Text>
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <Text>
                    Um eine Wiedergabeliste zu duplizieren, müssen Sie die Bearbeiten-Seite einer Wiedergabeliste aufrufen.
                  </Text>
                  <SupportInfoImage
                    chakraW={"xl"}
                    imgSrc="/img/support/edit_configuration_page_1.png"
                    imgText={"Beispielhafte Bearbeiten-Seite"}
                  />
                  <Text>
                    Am Kopf der Seite finden Sie die &quot;Löschen&quot; Schaltfläche. Klicken Sie diese um das Dialog-Fenster zu
                    öffnen.
                  </Text>
                  <Text>
                    Bitte beachten Sie, dass der Löschvorgang die Wiedergabeliste unwiderruflich löscht und diese nicht
                    wiederhergestellt werden kann.
                  </Text>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </>
        </AccordionPanel>
      </AccordionItem>
    )
  },
  MODERIEREN: (
    <>
      <Text>
        Über den Menüpunkt &quot;Moderieren&quot; erreichen Sie eine Auswahl Ihrer Produkte, welche Sie zur Moderation benutzen
        können.
      </Text>
      <SupportInfoImage chakraW={"2xl"} imgSrc="/img/support/moderation_page.png" imgText={"Moderieren Seite"} />
      <Text mb={4}>
        Wählen Sie zuerst ein oder mehrere Produkte aus, welche Sie benutzen möchten. Es können ein oder mehrere Produkte
        gleichzeitig moderiert werden, vorausgesetzt sie haben die selbe aktive Wiedergabeliste.
      </Text>
      <Text mb={2}>
        <Box color={"darkBlue.500"} fontWeight={600}>
          Hinweis:
        </Box>
        Bitte stellen Sie sicher, dass die Inhalte Ihrer Wiedergabeliste auf ihren VR-Brillen - mittels Synchronisation -
        aufgespielt sind.
      </Text>
      <Text>
        Zur Kontrolle können Sie den &quot;VR-Brillen&quot; Reiter benutzen. Hier werden alle VR-Brillen angezeigt, welche den
        jeweiligen Produkten zugeordnet sind. Brillen die als &quot;Unvollständig&quot; angezeigt werden könnten den gewählten
        Inhalt nicht synchronsiert haben. In dem Fall wird die Brille den Befehl nicht korrekt verarbeiten können.
      </Text>
      <SupportInfoImage chakraW={"2xl"} imgSrc="/img/support/moderate_device_tab.png" imgText={"VR-Brillen Reiter"} />
      <Text>
        Um einen Inhalt abzuspielen, wählen Sie diesen aus und drücken die &quot;Starten&quot;-Schaltfläche. Der Inhalt wird dann
        für alle verbundenen VR-Brillen gestartet.
      </Text>
      <SupportInfoImage
        chakraW={"2xl"}
        imgSrc="/img/support/play_content_example.png"
        imgText={"Schaltflächen zum Abspielen von Inhalten"}
      />
      <Text>
        Wird der Inhalt über das Portal gestartet werden die VR-Brillen bis zum Schließen der Wiedergabe in den geführten Modus
        versetzt. Die Benutzer der VR-Brillen können den Inhalt nicht selbstständig beenden. Mit Ausnahme der Web Apps. Hier
        haben Benutzer aufgrund von technischen Umständen die Möglichkeit bspw. die URL zu manipulieren.
      </Text>
      <SupportInfoImage chakraW={"2xl"} imgSrc="/img/support/play_content_bar.png" imgText={"Aktive Wiedergabe"} />
      <Text>
        Einen gestarteten Inhalt können Sie pausieren (im Falle eines Videos) oder die Wiedergabe durch Schließen der
        Aktionsleiste beenden.
      </Text>
      <Text>
        Bei Bedarf können einzelne VR-Brillen auch über den &quot;VR-Brillen&quot; Reiter gesteuert werden. So können Sie bspw.
        einen bestimmten Inhalt für eine bestimmte Brille starten, pausieren oder auch beenden.
      </Text>
      <Text>
        Hierzu benutzen Sie bspw. &quot;Starten&quot;-Schaltfläche, um den jeweiligen Inhalt für die VR-Brille zu starten
      </Text>
      <SupportInfoImage
        chakraW={"lg"}
        imgSrc="/img/support/moderate_play_single_device.png"
        imgText={"Modalfenster für einzelne VR-Brillen."}
      />
    </>
  ),
  MSB_ASSIGN_PRODUCTS: (
    <>
      <Text mb={2}>
        Mithilfe des VIL-Portals können Sie eines oder mehrere Ihrer Produkte unabhängig voneinander zuweisen. Sobald ein Produkt
        einer Schule zugewiesen wurde, kann es von der jeweiligen Schule genutzt werden.
        <Text mt={2}>
          <Box color={"darkBlue.500"} fontWeight={600}>
            Hinweis:
          </Box>
          Bitte beachten Sie, dass beim Verleih von Produkten die Wiedergabelisten der jeweiligen VR-Brillen zurückgesetzt
          werden. Mitarbeitende der Schule können anschließend ihre eigenen Wiedergabelisten erstellen. Installierte Inhalte auf
          den VR-Brillen bleiben dabei erhalten.
        </Text>
      </Text>
      <Accordion allowToggle reduceMotion>
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box flex="1" textAlign="left" py={"2"} h={"full"}>
                <Text fontSize={"md"}> </Text>
                <Text color={"darkBlue.500"} fontWeight={600} fontSize={"large"}>
                  Zuweisen
                </Text>
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <Text mb={2}>
              Um eines oder mehrere Produkte an eine Schule zuzuweisen, wählen Sie entweder direkt die Schaltfläche am jeweiligen
              Produkt aus oder nutzen Sie die Mehrfachauswahl, wenn mehrere Koffer zugewiesen werden sollen.
            </Text>

            <Text>
              Unabhängig davon, ob ein oder mehrere Produkte zugewiesen werden, muss im nächsten Schritt die Schule ausgewählt
              werden. Hierfür wählen Sie in dem sich öffnenden Fenster die Schule aus, die Zugriff auf die ausgewählten Koffer
              haben soll. Geben Sie einfach den Namen der Schule ein und wählen Sie diese in der Liste aus, sobald Sie die
              passende Schule gefunden haben. Danach bestätigen Sie Ihre Auswahl.
            </Text>
            <SupportInfoImage
              chakraW={"xl"}
              imgSrc="/img/support/school_select.png"
              imgText={"Modal-Fenster: Schule auswählen"}
            />
            <Text>
              Die Benutzer der Schule haben nun die Möglichkeit, sich mit ihrem Bildungsmediatheks-Account über das Portal
              einzuloggen. Sie werden automatisch der entsprechenden Schule zugeordnet und haben somit Zugriff auf das
              zugewiesene Produkt.
            </Text>

            <Text>
              <Box color={"darkBlue.500"} fontWeight={600}>
                Hinweis:
              </Box>
              Bitte beachten Sie, dass Ihre Produkte nur einer Schule zugeordnet werden können.
            </Text>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box flex="1" textAlign="left" py={"2"} h={"full"}>
                <Text fontSize={"md"}> </Text>
                <Text color={"darkBlue.500"} fontWeight={600} fontSize={"large"}>
                  Zuweisung beenden
                </Text>
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <Text>
              Um Ihr Produkt einer neuen Schule zuordnen zu können, muss zuerst die Zuweisung an die aktuelle Schule beendet
              werden. Klicken Sie hierfür auf den Namen der Schule
            </Text>
            <SupportInfoImage
              chakraW={"xl"}
              imgSrc="/img/support/end_transfer.png"
              imgText={"Modal-Fenster: Zuweisung beenden"}
            />

            <Text>Bestätigen Sie das Beeenden der Zuweisung.</Text>
            <Text>
              Die Daten der Schulen sind persistent. Das heißt, sollte der Koffer der Schule erneut zugewiesen werden, bleiben
              eigene Inhalte und Wiedergabelisten erhalten. Die Schulen können auch ohne zugewiesenes Produkt Wiedergabelisten
              erstellen oder eigene Inhalte hochladen.
            </Text>

            <Text mt={2}>
              <Box color={"darkBlue.500"} fontWeight={600}>
                Hinweis:
              </Box>
              Wie das Zuweisen ist auch das Beenden der Zuweisung über die Mehrfachauswahl möglich.
            </Text>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </>
  ),
  GRUPPIERUNGEN: {
    INTRO: (
      <Text my={4}>
        Gruppierungen ermöglichen es Ihnen, eines oder mehrere Ihrer Produkte unabhängig voneinander zu verwalten. Eine
        Gruppierung ist eine von Ihnen erstellte Portal-Instanz. Das bedeutet, dass Benutzer einer Gruppierung keinen Zugriff auf
        Produkte haben, die ihnen nicht explizit zugeordnet wurden. Sie sehen auch keine Inhalte oder Wiedergabelisten, es sei
        denn, diese werden mit der Gruppierung geteilt.
      </Text>
    ),
    ITEM_1: (
      <AccordionItem>
        <h2>
          <AccordionButton>
            <Box flex="1" textAlign="left" py={"2"} h={"full"}>
              <Text fontSize={"md"}> </Text>
              <Text color={"darkBlue.500"} fontWeight={600} fontSize={"large"}>
                Gruppierung erstellen
              </Text>
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4}>
          <Text>
            Um eine Gruppierung zu erstellen, navigieren Sie über die Produktverwaltung zur Übersicht Ihrer Gruppierungen. Auf
            der Seite angekommen, klicken Sie auf die Schaltfläche &quot;Gruppierung erstellen&quot;, um zur Erstellungsseite zu
            gelangen.
          </Text>
          <SupportInfoImage
            chakraW={"xl"}
            imgSrc="/img/support/empty_groups_selection.png"
            imgText={"Leere Gruppierungsseite"}
          />

          <Text>
            Zuerst müssen Sie einen Titel für die Gruppierung angeben. Danach können Sie eines oder mehrere Ihrer Produkte
            auswählen, um der Gruppierung Zugriff auf diese zu gewähren. Klicken Sie einfach auf die Schaltfläche, die das
            entsprechende Produkt darstellt.
          </Text>
          <Text>
            <Box color={"darkBlue.500"} fontWeight={600}>
              Hinweis:
            </Box>
            Bitte beachten Sie, dass Ihre Produkte nur einer Gruppierung zugeordnet werden können. Als Administrator behalten Sie
            unabhängig davon den Zugriff auf Ihre Produkte.
          </Text>
          <SupportInfoImage
            chakraW={"xl"}
            imgSrc="/img/support/create_group_index.png"
            imgText={"Gruppierungen: Erstellenseite"}
          />

          <Text my={4}>
            Wenn Sie mit Ihren Angaben zufrieden sind, klicken Sie auf die Schaltfläche &quot;Speichern&quot;, um die Gruppierung
            zu erstellen.
          </Text>
        </AccordionPanel>
      </AccordionItem>
    ),
    ITEM_2: (
      <AccordionItem>
        <h2>
          <AccordionButton>
            <Box flex="1" textAlign="left" py={"2"} h={"full"}>
              <Text fontSize={"md"}> </Text>
              <Text color={"darkBlue.500"} fontWeight={600} fontSize={"large"}>
                Gruppierung bearbeiten
              </Text>
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4}>
          <Text>Bestehende Gruppierungen können nach der Erstellung bearbeitet werden, um die Einrichtung abzuschließen.</Text>

          <Text>
            Um eine Gruppierung zu bearbeiten, können Sie zunächst die Detailseite aufrufen oder direkt zur Bearbeitungsmaske
            navigieren.
          </Text>

          <SupportInfoImage chakraW={"xs"} imgSrc="/img/support/example_group.png" imgText={"Beispielhafte Gruppierung"} />

          <Text>
            Auf der Bearbeitungsseite haben Sie wie beim Erstellen die Möglichkeit, den Titel der Gruppierung zu ändern oder
            weitere Produkte der Gruppierung zuzuordnen.
            <SupportInfoImage chakraW={"xs"} imgSrc="/img/support/example_group.png" imgText={"Beispielhafte Gruppierung"} />
          </Text>
          <Text>
            Darüber hinaus können Sie hier Benutzer einladen. Klicken Sie dafür auf die Schaltfläche &quot;Benutzer
            einladen&quot;. Es öffnet sich ein Modal-Fenster, in dem SieDarüber hinaus können auch hier Benutzer gelöscht werden.
            die E-Mail-Adresse des Benutzers eingeben, der eingeladen werden soll. Sie haben auch die Möglichkeit, Benutzer mit
            Administrationsrechten auszustatten.
          </Text>
          <Text>
            <Box color={"darkBlue.500"} fontWeight={600}>
              Hinweis:
            </Box>
            Nur Administratoren haben Zugriff auf die Gruppierungsoberflächen. Laden Sie daher mindestens einen Admin ein, wenn
            die Gruppierung autark arbeiten soll.
          </Text>
          <SupportInfoImage
            chakraW={"xl"}
            imgSrc="/img/support/example_group_user.png"
            imgText={"Beispielhafter Benutzer in Gruppierung"}
          />

          <Text mt={4}>
            Auch für bestehende Benutzer kann das Administratorenrecht vergeben oder wieder entzogen werden. Darüber hinaus
            können auch hier Benutzer gelöscht werden.
          </Text>

          <Divider my={4} />

          <Text>
            Wie beim Erstellen können Sie den Zugriff auf Ihre Produkte bearbeiten. Sie können bestehende Produkte anderen
            Gruppierungen zuordnen oder weitere Produkte hinzufügen.
          </Text>
          <SupportInfoImage
            chakraW={"md"}
            imgSrc="/img/support/product_display.png"
            imgText={"Gruppierung bearbeiten: Produkte"}
          />

          <Text>
            <Box color={"darkBlue.500"} fontWeight={600}>
              Hinweis:
            </Box>
            Bitte beachten Sie, dass das Zuordnen eines Produktes die Wiedergabeliste der jeweiligen VR-Brillen zurücksetzt.
          </Text>
        </AccordionPanel>
      </AccordionItem>
    ),
    ITEM_3: (
      <AccordionItem>
        <h2>
          <AccordionButton>
            <Box flex="1" textAlign="left" py={"2"} h={"full"}>
              <Text fontSize={"md"}> </Text>
              <Text color={"darkBlue.500"} fontWeight={600} fontSize={"large"}>
                Gruppierung löschen
              </Text>
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4}>
          <Text>Bestehende Gruppierungen können nachdem Sie Ihren Nutzen erfüllt haben wieder gelöscht werden.</Text>
          <Text>Um eine Gruppierung zu löschen, müssen Sie zunächst die Bearbeitenseite aufrufen.</Text>
          <Text>
            <Box color={"darkBlue.500"} fontWeight={600}>
              Hinweis:
            </Box>
            Beim Löschen von Gruppierungen werden alle Inhalte und Wiedergabelisten unwiderruflich gelöscht.
          </Text>
          <Text>
            Am Kopf der Seite befindet sich die Schaltfläche &quot;Gruppierung löschen&quot;. Betätigen Sie diese um den Prozess
            zu starten.
          </Text>
          <SupportInfoImage chakraW={"md"} imgSrc="/img/support/delete_group_modal.png" imgText={"Gruppierung löschen: Modal"} />
          <Text>
            Wenn eine Gruppierung gelöscht wird, haben Sie die Wahl ob Sie alle Benutzer der Gruppierung löschen wollen, oder
            diese zur Ihrer Organisation hinzufügen können.
          </Text>
          <Text>
            Darüber hinaus werden alle Produkte, welche der Gruppierung zugeordnet sind, wieder zu Ihrer Organisation
            zurückgeführt.
          </Text>
        </AccordionPanel>
      </AccordionItem>
    ),
    ITEM_4: (
      <AccordionItem>
        <h2>
          <AccordionButton>
            <Box flex="1" textAlign="left" py={"2"} h={"full"}>
              <Text fontSize={"md"}> </Text>
              <Text color={"darkBlue.500"} fontWeight={600} fontSize={"large"}>
                Produkte zuordnen
              </Text>
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4}>
          <Text>
            Das Zuordnen von Produkten zu erstellten Gruppierungen ist essenziell, damit diese über das Portal mit Ihren
            Produkten interagieren können, z.B. synchronisieren und moderieren.
          </Text>
          <Text>
            Zugeordnete Produkte können von den Gruppierungen frei genutzt werden. Gruppen können auch eigene Inhalte bzw.
            Wiedergabelisten erstellen und diese mit dem Produkt nutzen.
          </Text>
          <Text>Das Zuordnen von Produkten ist überall möglich, wo Sie Ihr Produkt finden können:</Text>
          <UnorderedList mb={4}>
            <ListItem> Adminbereich </ListItem>
            <ListItem> Gruppierung erstellen/bearbeiten </ListItem>
            <ListItem> Produktverwaltung </ListItem>
          </UnorderedList>
          <Text>
            Unabhängig von der gewählten Stelle können Produkte nur einer einzigen Gruppierung zugewiesen werden. Einmal
            zugewiesene Produkte können jederzeit einer anderen Gruppierung hinzugefügt oder zu Ihrer Organisation zurückgeführt
            werden.
          </Text>
          <Text>
            Anhand der entsprechenden Markierung können Sie den aktuellen &quot;Eigentümer&quot; des Produktes erkennen. Als
            Administrator haben Sie weiterhin Zugriff auf Produkte, die einer Gruppierung zugeordnet sind. Normale Benutzer Ihrer
            Organisation haben dieses Recht nicht.
          </Text>

          <SupportInfoImage chakraW={"xs"} imgSrc="/img/support/example_product_admin.png" imgText={"Adminansicht: Produkt"} />

          <Text>
            <Box color={"darkBlue.500"} fontWeight={600}>
              Hinweis:
            </Box>
            Sie als Administrator haben weiterhin Zugriff auf Produkte, welche einer Gruppierung zugeordnet sind. Normaler
            Benutzer Ihrer Organsiation haben dieses Recht nicht.
          </Text>

          <Text>
            Wenn ein Produkt einer Gruppierung zugeordnet ist, können Sie als Administrator weiterhin auf die VR-Brillen
            zugreifen und Befehle senden. Die einzigen Einschränkungen betreffen den Bereich der Wiedergabelisten.
          </Text>
          <OrderedList>
            <ListItem>
              Wird ein Produkt einer anderen Gruppierung zugeordnet oder zu Ihrer Organisation zurückgeführt, werden die
              Wiedergabelisten der VR-Brillen zurückgesetzt. Die erste Handlung einer Gruppierung sollte daher das Erstellen bzw.
              Aktivieren einer Wiedergabeliste sein, um das Portal nutzen zu können.
            </ListItem>
            <ListItem>
              Das Aktivieren von Wiedergabelisten ist für zugewiesene Produkte nicht möglich. Das heißt, Sie als Administrator
              können keine Ihrer Wiedergabelisten für ein Produkt aktivieren, das einer Gruppierung zugeordnet ist. Hierfür
              sollten Sie die Wiedergabeliste teilen.
            </ListItem>
          </OrderedList>
        </AccordionPanel>
      </AccordionItem>
    ),
    ITEM_5: (
      <AccordionItem>
        <h2>
          <AccordionButton>
            <Box flex="1" textAlign="left" py={"2"} h={"full"}>
              <Text fontSize={"md"}> </Text>
              <Text color={"darkBlue.500"} fontWeight={600} fontSize={"large"}>
                Teilen von Inhalten / Wiedergabelisten
              </Text>
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4}>
          <Text>
            Mit bestehenden Gruppierungen haben Sie die Möglichkeit eigene Inhalte, als auch von Ihnen erstellte Wiedergabelisten
            zu teilen.
          </Text>
          <Text>
            Sowohl geteilte Inhalte als auch Wiedergabelisten können nicht von den Gruppierungen weitergeteilt oder bearbeitet
            werden.
          </Text>
          <Text my={2} fontWeight={600} fontFamily="Bitter" fontSize={"lg"} color="darkBlue.500" as="h3">
            Inhalte
          </Text>
          <Text>
            Um eigene Inhalte zu teilen, müssen Sie als erstes einen Ihrer eigenen Inhalte aus der Mediathek auswählen.
          </Text>
          <SupportInfoImage
            chakraW={"sm"}
            imgSrc="/img/support/owned_content_example.png"
            imgText={"Beispiel: Eigener Inhalt"}
          />
          <Text>
            Bei eignen Inhalten werden Sie den Reiter Teilen sehen können. In diesem finden Sie die Schaltfläche zum Teilen des
            Inhalts.
          </Text>
          <Text>
            Nach Betätigung öffnet sich das entsprechende Modal Fenster, in welchem Sie eine oder meherer Gruppierungen auswählen
            können, welche den Inhalt benutzen können sollen.
          </Text>
          <Text>Haben Sie Ihre Auswahl getroffen bestätigen Sie diese.</Text>
          <Text>Danach finden Sie im Teilen Bereich des Inhalts die Gruppierungen, welche Zugriff auf den Inhalt haben.</Text>
          <SupportInfoImage
            chakraW={"sm"}
            imgSrc="/img/support/owned_content_shared_hint.png"
            imgText={"Eigener Inhalte: Gruppierungen"}
          />

          <Text> Bei Bedarf können Sie hier auch das Teilen wieder beenden.</Text>
          <Text>
            <Box color={"darkBlue.500"} fontWeight={600}>
              Hinweis:
            </Box>
            Beim beenden des Teilens wird der Inhalt nicht von bestehende VR-Brillen entfernt, sondern nur aus der Mediathek und
            Wiedergabelisten der Gruppierung.
          </Text>
          <Text my={2} fontWeight={600} fontFamily="Bitter" fontSize={"lg"} color="darkBlue.500" as="h3">
            Wiedergabelisten
          </Text>

          <Text>
            Auch Wiedergabelisten können mit bestehnden Gruppierungen geteilt werden, um den Gruppierungen zu ermöglichen die von
            Ihnen erstellten Wiedergabelisten für Ihre Produkte zu aktivieren.
          </Text>
          <Text>
            Zuerst müssen Sie auf die Detailseite der gewünschten Wiedergabeliste navigieren. Den Link finden Sie unter der
            Produktverwaltung.
          </Text>
          <SupportInfoImage
            chakraW={"lg"}
            imgSrc="/img/support/detail_page_config.png"
            imgText={"Detailseite: Wiedergabeliste"}
          />
          <Text>
            Auf der Detailseite angekommen können Sie oben rechts die Schaltfläche &quot;Teilen&quot; finden. Betätigen Sie
            diese, um den Prozess zu beginnen.
          </Text>
          <Text>
            Nach Betätigung öffnet sich ein Modal Fenster, in welchem Sie aus Ihren Gruppierungen eine oder mehrere Auswählen
            können, mit welcher die Wiedergabeliste geteilt werden soll.
          </Text>

          <Text>
            Haben Sie Ihre Auswahl bestätigt so finden Sie am Fuß der Seite alle Gruppierungen, mit denen die Wiedergabeliste
            geteilt wird.
          </Text>
          <SupportInfoImage
            chakraW={"xl"}
            imgSrc="/img/support/config_shared_hint.png"
            imgText={"Wiedergabeliste: Gruppierungen"}
          />

          <Text>Um das Teilen zu beenden können Sie in diesem Bereich die entsprechenden Schaltflächen bedienen.</Text>
          <Text>
            <Box color={"darkBlue.500"} fontWeight={600}>
              Hinweis:
            </Box>
            Beim beenden des Teilens von Wiedergabelisten wird nicht das Teilen von ggf. enthaltenen eigenen Inhalten beendet.
          </Text>
        </AccordionPanel>
      </AccordionItem>
    )
  },
  VIL_CONNECT: (
    <>
      <Text>
        Für Nutzer*innen, welche Probleme mit dem Einsatz der VR-Brillen haben (u. a. Motion Sickness) stellen wir ergänzend die
        <Text as={"span"} display={"inline"} color={"darkBlue.500"} fontWeight={600}>
          <br /> VIL Connect-App
        </Text>
        zur Verfügung.
      </Text>
      <Box my={4}>
        <Text mb={2} color={"darkBlue.500"} fontWeight={600}>
          Vorraussetzungen / Einschränkungen
        </Text>
        <OrderedList>
          <ListItem>Im Moment ist die App nur im Google Playstore und somit nur auf Android Geräten verfügbar.</ListItem>
          <SupportInfoImage imgSrc="/img/support/vil_connect.png" imgText={"Darstellung der App im Google Playstore"} />
          <ListItem>
            Um die VIL Connect-App nutzen zu können, benötigen Sie eine VIL-Komplettlösung (Lernwagen/Lernpult). Für unseren
            Lernkoffer besteht keine Kompabilität.
          </ListItem>
          <ListItem>
            Das Tablet bzw. Android-Device, auf welchem die VIL Connect App installiert ist, muss mit dem selben Netzwerk
            verbunden sein wie Ihre VIL-Komplettlösung. Hierzu finden Sie ein Element im Menü, welches Ihnen SSID und Passwort
            zur Verfügung stellt.
          </ListItem>
          <ListItem>
            Die VIL Connect ermöglicht es Ihnen ausschließlich Inhalte vom Typ
            <Text as={"span"} display={"inline"} color={"darkBlue.500"} fontWeight={600}>
              Video
            </Text>
            auf die App zu übertragen.
            <Text as={"span"} display={"inline"} color={"darkBlue.500"} fontWeight={600}>
              Anwendungen
            </Text>
            werden nicht unterstützt.
          </ListItem>
        </OrderedList>
      </Box>
      <Box my={4}>
        <Text mb={2} color={"darkBlue.500"} fontWeight={600}>
          Benutzung der App
        </Text>
        <OrderedList>
          <ListItem>
            Zunächst sollte sichergestellt werden, dass das Mobilgerät mit dem WLAN Ihrer VIL-Komplettlösung verbunden ist.
          </ListItem>
          <ListItem>Anschließend sollte die App über den Google Play Store heruntergeladen und installiert werden.</ListItem>
          <ListItem>
            Beim Starten der App wird die Standorterlaubnis benötigt, um Zugriff auf das WLAN-Netzwerk zu erhalten und die
            Anzeige der WLAN-Verbindung sowie des Netzwerknamens zu ermöglichen
          </ListItem>
          <ListItem>Die App wird nun eine Verbindung mit dem WLAN Ihrer VIL-Komplettlösung herstellen.</ListItem>
          <ListItem>Sobald der folgende Bildschirm angezeigt wird, ist die App einsatzbereit.</ListItem>
          <SupportInfoImage
            chakraW="md"
            imgSrc="/img/support/vil_mobile_client.jpg"
            imgText={"Darstellung der App auf dem Gerät"}
          />

          <ListItem>
            Wird die folgende Ansicht angezeigt, kann das gewünschte Video auf das entsprechende Android-Device gestreamt werden.
          </ListItem>
          <Text fontWeight={600} color={"darkBlue.500"}>
            Wichtig:
          </Text>
          <Text>
            Bitte stellen Sie sicher, dass die VIL-Komplettlösung selbst das Video abspielt. Falls dies nicht der Fall ist,
            stellen Sie sicher, dass die Komplettlösung ordnungsgemäß mit dem Internet verbunden ist und starten Sie es neu.
          </Text>
        </OrderedList>
      </Box>
    </>
  ),
  VIL_LAUNCHER: {
    ITEM_1_DEFAULT: (
      <AccordionItem>
        <h2>
          <AccordionButton>
            <Box flex="1" textAlign="left" py={"2"} h={"full"}>
              <Text color={"darkBlue.500"} fontWeight={600} fontSize={"large"}>
                Optionen
              </Text>
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4}>
          <Divider my={4} />
          <Text fontFamily={"Bitter"} mb={2} fontWeight={600} color="darkBlue.500">
            Allgemein:
          </Text>
          <Text mb={2}>Die Optionen des VIL Launchers können mit 2 PIN-Codes bedient werden.</Text>
          <Text mb={2}>
            Der allgemeine PIN-Code ist für die Moderation der VR-Brillen gedacht. Der Admin-PIN kann für alle Optionen verwendet
            werden. Manche Optionen können jedoch nur mit dem Admin-PIN erreicht werden.
          </Text>
          <Text>PIN für Moderation:</Text>
          <Text fontSize={"lg"} my={2}>
            3481
          </Text>

          <Text mb={4}>
            Um Option ändern zu können muss der Benutzer sich im Launcher befinden. Als erstes muss mithilfe der Pfeiltasten nach
            links navigiert werden. Dort befindet sich das Optionen-Panel.
          </Text>
          <Text>
            Hier können Sie nun entweder mit dem Schloss-Symbol die Optionen freischalten, oder direkt die Einstellung anwählen,
            um die PIN-Abfrage zu öffnen.
          </Text>
          <SupportInfoImage chakraW="sm" imgSrc="/img/support/options_panel.jpeg" imgText={"Darstellung vom Optionen Bereich"} />
          <Divider my={4} />
          <Text fontFamily={"Bitter"} mb={2} fontWeight={600} color="darkBlue.500">
            Spielbereich:
          </Text>

          <Text mb={4}>
            Der Spielbereich definiert den virtuellen Raum in dem sich der Träger der Brille befindet. Mit Hilfe dieser Option
            kann die Größe dieses Raumes bestimmt werden.
          </Text>

          <Text>Nach erfolgreicher Eingabe erhalten Sie Zugriff auf die Option.</Text>
          <Text>Hier können Sie zwischen den Voreinstellungen wählen. Oder den Bereich manuell einrichten.</Text>
          <Text mb={2}>
            <Box color={"darkBlue.500"} fontWeight={600}>
              Hinweis:
            </Box>
            Die Option des Spielbereiches ist nicht Sitzungsgebunden. D.h. die eingestellten Werte bleiben auch nach dem
            Herunterfahren vorhanden.
          </Text>

          <Text mb={4}>Um auf die Einstellung zugreifen zu können ist ein PIN-Code notwendig.</Text>
        </AccordionPanel>
      </AccordionItem>
    ),
    ITEM_1_ADMIN: (
      <AccordionItem>
        <h2>
          <AccordionButton>
            <Box flex="1" textAlign="left" py={"2"} h={"full"}>
              <Text color={"darkBlue.500"} fontWeight={600} fontSize={"large"}>
                Optionen
              </Text>
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4}>
          <Divider my={4} />
          <Text fontFamily={"Bitter"} mb={2} fontWeight={600} color="darkBlue.500">
            Allgemein:
          </Text>
          <Text mb={2}>Die Optionen des VIL Launchers können mit 2 PIN-Codes bedient werden.</Text>
          <Text mb={2}>
            Der allgemeine PIN-Code ist für die Moderation der VR-Brillen gedacht. Der Admin-PIN kann für alle Optionen verwendet
            werden. Manche Optionen können jedoch nur mit dem Admin-PIN erreicht werden.
          </Text>
          <Text>PIN für Moderation:</Text>
          <Text fontSize={"lg"} my={2}>
            3481
          </Text>

          <Text>PIN für Administration:</Text>
          <Text fontSize={"lg"} my={2}>
            9678
          </Text>

          <Text mb={4}>
            Um Option ändern zu können muss der Benutzer sich im Launcher befinden. Als erstes muss mithilfe der Pfeiltasten nach
            links navigiert werden. Dort befindet sich das Optionen-Panel.
          </Text>
          <Text>
            Hier können Sie nun entweder mit dem Schloss-Symbol die Optionen freischalten, oder direkt die Einstellung anwählen,
            um die PIN-Abfrage zu öffnen.
          </Text>
          <SupportInfoImage chakraW="sm" imgSrc="/img/support/options_panel.jpeg" imgText={"Darstellung vom Optionen Bereich"} />
          <Divider my={4} />
          <Text fontFamily={"Bitter"} mb={2} fontWeight={600} color="darkBlue.500">
            Spielbereich:
          </Text>

          <Text mb={4}>
            Der Spielbereich definiert den virtuellen Raum in dem sich der Träger der Brille befindet. Mit Hilfe dieser Option
            kann die Größe dieses Raumes bestimmt werden.
          </Text>

          <Text>Nach erfolgreicher Eingabe erhalten Sie Zugriff auf die Option.</Text>
          <Text>Hier können Sie zwischen den Voreinstellungen wählen. Oder den Bereich manuell einrichten.</Text>
          <Text mb={2}>
            <Box color={"darkBlue.500"} fontWeight={600}>
              Hinweis:
            </Box>
            Die Option des Spielbereiches ist nicht Sitzungsgebunden. D.h. die eingestellten Werte bleiben auch nach dem
            Herunterfahren vorhanden.
          </Text>

          <Text mb={4}>Um auf die Einstellung zugreifen zu können ist ein PIN-Code notwendig.</Text>

          <Divider my={4} />
          <Text mb={2} fontFamily={"Bitter"} fontWeight={600} color="darkBlue.500">
            Herstellermodus:
          </Text>

          <Text mb={4}>
            Der Herstellermodus ermöglicht es Ihnen in das Betriebssystem des Brillenherstellers zu wechseln. Hier bekommen sie
            vollen Zugriff auf ihr Gerät und können alle Einstellungen an der Brille vornehmen wie sie im Originalzustand möglich
            sind.
          </Text>
          <Text>
            Wird der Herstellermodus für eine Brille aktiviert, so startet diese neu, um dann in der Umgebung des Herstellers zu
            starten. Sobald das Gerät neustartet befinden Sie sich wieder im geschützten VIL Launcher.
          </Text>
          <Text mb={2}>
            <Box color={"darkBlue.500"} fontWeight={600}>
              Hinweis:
            </Box>
            Der Herstellermodus ist mit Vorsicht zu bedienen und sollte nur von geschultem Personal benutzt werden, da Änderungen
            der Einstellungen auch die Funktionalität des MDMs aushebeln können.
          </Text>

          <Text mb={4}>
            Nach korrekter Eingabe wurde der Herstellermodus freigeschaltet und Sie können dort den Herstellermodus starten.
            Anschließend wird sich die Brille neu starten, um den Herstellermodus zu aktivieren.
          </Text>
        </AccordionPanel>
      </AccordionItem>
    )
  }
};
