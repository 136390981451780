import { Image, Text, Box, Link } from "@chakra-ui/react";
interface ISupportInfoImageProps {
  imgSrc: string;
  imgText: string;
  chakraW?: string;
}

export default function SupportInfoImage({ imgSrc, imgText, chakraW = "sm" }: ISupportInfoImageProps) {
  return (
    <Link target={"_blank"} href={imgSrc}>
      <Box cursor={"pointer"} my={4} w={"full"}>
        <Image mx={"auto"} boxShadow="lg" w={chakraW} src={imgSrc} alt={imgText}></Image>
        <Text mt={2} textAlign={"center"} fontSize={"sm"} color={"gray.500"}>
          {imgText}
        </Text>
      </Box>
    </Link>
  );
}
